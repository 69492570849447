import React, { useState, useMemo, useEffect } from "react";
import "./Job.scss";
import { Card, Table } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import SoftButton from "StyleFeatures/SoftButton";
import axios from "axios";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default function AvailableTraining({ selectedEmail }) {
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (selectedEmail) {
      // Ensure selectedEmail is available before fetching data
      fetchData();
    }
  }, [selectedEmail]);

  const [dataSource, setDataSource] = useState([]);

  async function fetchData() {
    try {
      const response = await axios.get(`${apiUrl}/api/TrainingDevelopement/Get-Training-Detail`);
      const responseData = response.data;
      const trainingDetail = responseData.trainingDetail;

      // Generate rows based on the fetched data
      const updatedRows = trainingDetail.map((info, index) => {
        function formatDateTime(dateTimeString) {
          const options = {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          };

          return new Date(dateTimeString).toLocaleString("en-US", options);
        }

        return {
          key: info.trainingId.toString(),
          Name: `${info.trainingName}`,
          Provider: `${info.provider}`,
          Mentor: info.mentor,
          Enrollments: "N/A",
          Allocated_budget: info.allocated_budget,
          StartDate: <span>{formatDateTime(info.startDate)}</span>,
          EndDate: <span>{formatDateTime(info.endDate)}</span>,
          Action: (
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link
                to={{
                  pathname: `/employeedashboard/enrolledTrainingEmployee/${info.trainingId}`,
                  // state: { selectedEmail }, // Ensure selectedEmail is passed in the state
                }}
              >
                <SoftButton variant="outlined" color="info" size="small">
                  Recommend
                </SoftButton>
              </Link>
            </span>
          ),
        };
      });

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "Mentor",
      dataIndex: "Mentor",
      key: "Mentor",
    },
    {
      title: "Enrollments",
      dataIndex: "Enrollments",
      key: "Enrollments",
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "StartDate",
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      key: "EndDate",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  return (
    <Card title="Available Trainings" className="card-professional">
      <div className="row">
        <Table
          className="table-responsive"
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={{ pageSize: 10 }}
        />
      </div>
    </Card>
  );
}

AvailableTraining.propTypes = {
  selectedEmail: PropTypes.string,
};
