import React, { useState, useEffect } from "react";
import Personal from "../Personal/Personal";
import JobS from "../Job/Job";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "./ParentComponent.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import { BadgeOutlined, ChatRounded } from "@mui/icons-material";
import Assets from "Components/Assets/Assets";
import { useLocation } from "react-router-dom";

const ParentComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const employeeDetail = location.state?.employeeDetail || null;

  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage === 8 ? 1 : prevPage + 1));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? 8 : prevPage - 1));
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="stepper-wrapper">
            <div className={`stepper-item initial ${currentPage >= 1 ? "completed" : ""}`}>
              <div className={`step-counter ${currentPage === 1 ? "active" : ""}`}>
                <InfoCircleFilled />
              </div>
              <div className="step-name">Personal</div>
            </div>
            <div className={`stepper-item ${currentPage >= 2 ? "completed" : ""}`}>
              <div className={`step-counter ${currentPage === 2 ? "active" : ""}`}>
                <BadgeOutlined />
              </div>
              <div className="step-name">Job</div>
            </div>
            {/* <div className={`stepper-item ${currentPage >= 3 ? "completed" : ""}`}>
              <div className={`step-counter ${currentPage === 3 ? "active" : ""}`}>
                <ChatRounded />
              </div>
              <div className="step-name">Assets</div>
            </div> */}
          </div>
          {currentPage === 1 && (
            <Personal employeeDetail={employeeDetail} onNext={handleNextPage} />
          )}
          {currentPage === 2 && (
            <JobS
              employeeDetail={employeeDetail}
              onNext={handleNextPage}
              onBack={handlePreviousPage}
            />
          )}
          {/* {currentPage === 3 && <Assets onNext={handleNextPage} onBack={handlePreviousPage} />} */}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ParentComponent;
