import React, { useState, useEffect } from "react";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import { Input, Row, Col, Form, Card } from "antd";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import axios from "axios";

function Personal() {
  useEffect(() => {
    fetchEmployeesByEmail();
  }, []);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);

  async function fetchEmployeesByEmail() {
    try {
      setLoading(true);
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/PersonalInformation/GetEmployeeby-Email/${loginEmail}`
        );
        const responseData = response.data;

        if (Array.isArray(responseData)) {
          setEmployeeDetails(responseData);
        } else {
          setEmployeeDetails([responseData]);
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Personal Information" className="card-professional" loading={loading}>
            {employeeDetails.map((employee) => (
              <Form layout="vertical" key={employee.employeeNumber}>
                {/* Basic Information Section */}
                <label className="mb-3">
                  <i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;BASIC INFORMATION
                </label>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Employee Number">
                      <Input disabled value={employee.employeeNumber} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="First Name">
                      <Input disabled value={employee.firstName} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Last Name">
                      <Input disabled value={employee.lastName} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Gender">
                      <Input disabled value={employee.gender} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Marital Status">
                      <Input disabled value={employee.maritalStatus} />
                    </Form.Item>
                  </Col>
                </Row>

                {/* Contact Information Section */}
                <label className="mb-3">
                  <i className="fa fa-phone-square" aria-hidden="true"></i>&nbsp;CONTACT
                </label>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Mobile Phone">
                      <PhoneInput
                        disabled
                        value={employee.mobileno}
                        defaultCountry="us"
                        inputStyle={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Work Email">
                      <Input disabled value={employee.workEmail} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Home Email">
                      <Input disabled value={employee.email} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Work Authorization US">
                      <Input disabled value={employee.workAuthorizationUS} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Sponsorship Needed">
                      <Input disabled value={employee.sponsorshipNeeded} />
                    </Form.Item>
                  </Col>
                </Row>

                {/* Address Section */}
                <label className="mb-3">
                  <i className="fa fa-home" aria-hidden="true"></i>&nbsp;ADDRESS
                </label>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Street Address">
                      <Input disabled value={employee.streetAddress} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="City">
                      <Input disabled value={employee.city} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="State">
                      <Input disabled value={employee.state} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Postal/Zip Code">
                      <Input disabled value={employee.postalCode} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Country">
                      <Input disabled value={employee.country} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            ))}
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Personal;
