import React, { useState, useEffect } from "react";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import { Button, Card, Col, Form, Input, Row, Table } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import ProfileCard from "./ProfileCard";
import axios from "axios";
import PerformanceFactor from "./PerformanceFactor";
import DynamicOrganization from "./DynamicOrganization";
import { useLocation } from "react-router-dom";
import AvailableTraining from "./AvailableTraining";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Job() {
  const [showInitiate, setShowInitiate] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedEmail, setSelectedEmail] = useState({ email: "", personalId: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const [dataSource, setDataSource] = useState([]);
  const employeeDetail = location.state?.employeeDetail || null;
  const [loading, setLoading] = useState(false);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage === 3 ? 1 : prevPage + 1));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? 3 : prevPage - 1));
  };

  useEffect(() => {
    fetchEmployeesByEmail();
    fetchEmployee();
  }, []);
  const [employeeDetails, setEmployeeDetails] = useState([]);

  async function fetchEmployeesByEmail() {
    try {
      setLoading(true);
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/Job/GetEmployee-JobDataby-Email/${loginEmail}`
        );
        const responseData = response.data;

        if (Array.isArray(responseData)) {
          setEmployeeDetails(responseData);
          if (responseData.length > 0) {
            const managerName = responseData[0].manager; // Assuming manager name is in the first employee data
            fetchEmployee(managerName);
          }
        } else {
          setEmployeeDetails([responseData]);
          if (responseData.manager) {
            fetchEmployee(responseData.manager);
          }
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleInitiateClick = (email, personalId) => {
    setShowInitiate(false);
    setSelectedEmail({ email, personalId });
  };

  const column = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Job Title",
      dataIndex: "JobTitle",
      key: "JobTitle",
    },
    {
      title: "Department",
      dataIndex: "Team",
      key: "Team",
    },
    {
      title: "Effective Date",
      dataIndex: "EffectiveDate",
      key: "EffectiveDate",
    },
    {
      title: "Review",
      dataIndex: "Review",
      key: "Review",
    },
  ];

  function formatDateTime(dateTimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }

  async function fetchEmployee() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/Job/GetEmployee-JobDataby-Email/${loginEmail}`
        );
        const responseData = response.data;
        const managerName =
          responseData.personalInformation.firstName +
          " " +
          responseData.personalInformation.lastName;

        const employeesResponse = await axios.get(
          `${apiUrl}/api/Job/GetEmployees-JobDataby-Manager/${managerName}`
        );
        const employeesData = employeesResponse.data;

        if (Array.isArray(employeesData) && employeesData.length > 0) {
          // Filter employees whose manager is the logged-in user
          const filteredEmployees = employeesData.filter(
            (employee) => employee.manager === managerName
          );

          if (filteredEmployees.length > 0) {
            // If manager's employees are found, map the response data to match the datasource format
            const mappedData = filteredEmployees.map((employee, index) => ({
              key: index + 1,
              PersonalId: employee.personalInformation.personalId,
              Name: `${employee.personalInformation.firstName} ${employee.personalInformation.lastName}`,
              JobTitle: employee.forJobApply,
              Team: employee.team,
              EffectiveDate: employee.hiringDate,
              Email: employee.personalInformation.email,
              Review: (
                <Button
                  type="primary"
                  onClick={() =>
                    handleInitiateClick(
                      employee.personalInformation.email,
                      employee.personalInformation.personalId
                    )
                  }
                  disabled={
                    employee.personalInformation.status === 0 ||
                    employee.personalInformation.status === 2
                  }
                >
                  {employee.personalInformation.status === 2 ? "Initiated" : "Initiate"}
                </Button>
              ),
            }));

            setDataSource(mappedData);
          } else {
            // If no employee data found, set data source to an empty array and display a message
            console.error("No employee data found");
            setDataSource([]);
          }
        } else {
          console.error("No employee data found");
          setDataSource([]);
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          {showInitiate && (
            <Card title="Job" className="card-professional" loading={loading}>
              {employeeDetails.map((employee) => (
                <Form layout="vertical" key={employee.employeeNumber}>
                  {/* Job Apply Section */}
                  <Row gutter={16}>
                    <Col span={24}>
                      <label className="mb-3" style={{ textAlign: "left" }}>
                        <i className="fa fa-suitcase" aria-hidden="true"></i>&nbsp;
                        {employee.forJobApply}
                      </label>
                    </Col>

                    {/* Hiring Date */}
                    <Col span={9}>
                      <Form.Item label="Hiring Date">
                        <Input value={formatDateTime(employee.hiringDate)} disabled />
                      </Form.Item>
                    </Col>

                    {/* Department */}
                    <Col span={9}>
                      <Form.Item label="Department">
                        <Input value={employee.team} disabled />
                      </Form.Item>
                    </Col>

                    {/* Probation Start Date */}
                    <Col span={9}>
                      <Form.Item label="Probation Start Date">
                        <Input value={formatDateTime(employee.probationStartDate)} disabled />
                      </Form.Item>
                    </Col>

                    {/* Probation End Date */}
                    <Col span={9}>
                      <Form.Item label="Probation End Date">
                        <Input value={formatDateTime(employee.probationEndDate)} disabled />
                      </Form.Item>
                    </Col>

                    {/* Manager */}
                    <Col span={24}>
                      <Form.Item label="Your Manager">
                        <div style={{ marginLeft: "-25px" }}>
                          <ProfileCard />
                        </div>
                      </Form.Item>
                    </Col>

                    {/* Direct Reports Table */}
                    <Col span={24}>
                      <Form.Item label="Direct Reports">
                        <Table
                          className="table-responsive"
                          bordered
                          dataSource={dataSource}
                          columns={column}
                          pagination={{ pageSize: 10 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              ))}
            </Card>
          )}
          {/* {!showInitiate && <InitiateAccountability />} */}
          {!showInitiate && (
            <>
              {currentPage === 1 && (
                <PerformanceFactor
                  selectedEmail={selectedEmail}
                  employeeDetail={employeeDetail}
                  onNext={handleNextPage}
                  onBack={handlePreviousPage}
                />
              )}
              {currentPage === 2 && (
                <DynamicOrganization
                  selectedEmail={selectedEmail}
                  employeeDetail={employeeDetail}
                  onNext={handleNextPage}
                  onBack={handlePreviousPage}
                />
              )}
              {currentPage === 3 && (
                <AvailableTraining
                  selectedEmail={selectedEmail}
                  onNext={handleNextPage}
                  onBack={handlePreviousPage}
                />
              )}
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Job;
