import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateEnrolledInfo } from "../../../Components/Services/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

export function EnrolledTrainingService({ selectedEmail }) {
  const { trainingId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate=useNavigate();
  const { initiateData, performanceData, dynamicOrganizationData } = useSelector((state) => state);

  const [trainingDetail, setTrainingDetail] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [enrolledFormData, setEnrolledFormdata] = useState({
    personalId: null,
    trainingId: "",
    trainingName: "",
    provider: "",
    email: "",
    mentor: "",
    allocated_budget: "",
    startDate: "",
    endDate: "",
    rating: "",
  });
  useEffect(() => {
    if (selectedEmail && selectedEmail.email && selectedEmail.personalId !== null) {
      console.warn("Selected Email:", selectedEmail.email);
      console.warn("Selected PersonalId:", selectedEmail.personalId);

      setEnrolledFormdata((prevState) => {
        const updatedState = {
          ...prevState,
          email: selectedEmail.email,
          personalId: selectedEmail.personalId,
        };
        return updatedState;
      });
    }
    fetchTrainingDetail();
  }, [selectedEmail]);

  async function fetchTrainingDetail() {
    try {
      const email = localStorage.getItem("loginEmail");
      const response = await axios.get(
        `${apiUrl}/api/TrainingDevelopement/GetAllByTrainingId/${trainingId}`
      );
      setTrainingDetail(response.data);

      // Populate enrolledFormData with trainingDetail
      setEnrolledFormdata({
        trainingId: trainingId,
        trainingName: response.data.trainingInfo.trainingName || "",
        provider: response.data.trainingInfo.provider || "",
        mentor: response.data.trainingInfo.mentor || "",
        allocated_budget: response.data.trainingInfo.allocated_budget || "",
        startDate: response.data.trainingInfo.startDate || "",
        endDate: response.data.trainingInfo.endDate || "",
        rating: response.data.trainingInfo.rating || "",
      });
    } catch (error) {
      console.error("Error fetching employee detail:", error);
    }
  }

  const handleEnrolledChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;
    setEnrolledFormdata({ ...enrolledFormData, [name]: value });
    setErrorMessage("");
  };

  const dispatch = useDispatch();

  const handleNext = async (e) => {
    e.preventDefault();

    // Validation logic for the rating field
    const rating = enrolledFormData.rating;

    if (!rating) {
      setErrorMessage("Rating is required");
      return;
    }

    if (rating <= 0) {
      setErrorMessage("Rating cannot be null or negative.");
      return;
    }

    if (rating > 5) {
      setErrorMessage("Rating cannot be greater than 5.");
      return;
    }

    // Reset error message if validation passes
    setErrorMessage("");

    // Proceed with form submission
    dispatch(updateEnrolledInfo(enrolledFormData));

    const formData = new FormData();
    Object.entries(performanceData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    Object.entries(dynamicOrganizationData).forEach(([key, value]) => {
      formData.append(`DynamicOrganizationDto.${key}`, value);
    });

    Object.entries(enrolledFormData).forEach(([key, value]) => {
      formData.append(`EnrolledTrainingEmployeeDto.${key}`, value);
    });

    try {
      setIsLoading(true);
      await axios.post(`${apiUrl}/api/InitiateAccountability/AddInitiate-Data`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Performance review has Been Send to HR Manager Successfully", {
        autoClose: 1000,
        style: { background: "lightgreen", color: "white" },
      });
      handlejobnavigate();
    } catch (error) {
      console.error("Error adding enrolled information:", error);
      toast.error(error.message || "Failed to add enrolled information", {
        autoClose: 5000,
        style: { background: "lightcoral", color: "white" },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlejobnavigate=()=>{
    navigate("/employeedashboard/jobemployee")
  }

  return {
    enrolledFormData,
    setEnrolledFormdata,
    isLoading,
    handleNext,
    setIsLoading,
    handleEnrolledChange,
    trainingDetail,
    errorMessage
  };
}
