import React from "react";
import { Navigate, Route } from "react-router-dom";

// Employee Components
import EmployeeSignIn from "EmployeeDashboard/authentication/sign-in/signIn";
import FinalSignIn from "EmployeeDashboard/authentication/Final-Signin/Final-Login";
import EmployeeRequestTimeOff from "EmployeeDashboard/RequestTimeOff/RequesttimeOff";
import ShortListEmployeeForm from "EmployeeDashboard/ShortListEmployeeForm/ShortListEmployeeForm";
import InterviewSchedule from "EmployeeDashboard/ShortListEmployeeForm/InterviewSchedule";
import EmployeeTrainingDetail from "EmployeeDashboard/Training/TrainingDetail";
import EmployeeComplaintDetail from "EmployeeDashboard/Complaints/ComplaintDetail";
import ProtectedRoute from "EmployeeDashboard/authentication/ProtectedRoute/ProtectedRoute";
import EnrolledTrainingEmployee from "EmployeeDashboard/Job/EnrolledTrainingEmployee";
import EmployeeExpenseRequest from "EmployeeDashboard/ExpenseManagment/EmployeeExpenseRequest";
import EmployeeAnnouncementDetail from "EmployeeDashboard/dashboard/components/Announcement/employeeAnnouncementDetail";
import AddEmployeeExpense from "EmployeeDashboard/ExpenseManagment/ExpenseManagment";
import EmployeeOnboarding from "./EmpoyeeOnboard/EmployeeOnboarding";
const RedirectToDashboard = () => <Navigate to="/employeedashboard" />;
const EmployeeRoutes = () => (
  <React.Fragment>
    <Route path="/" element={<ProtectedRoute element={<RedirectToDashboard />} />} />
    <Route path="/empsignIn" name="Sign In" element={<EmployeeSignIn />} />
    <Route
      path="/Employee-Onboarding/:empId/:email"
      name="Employee-Onboarding"
      element={<ProtectedRoute element={<EmployeeOnboarding />} />}
    />

    <Route path="/empLogin" name="Sign In" element={<FinalSignIn />} />
    <Route
      path="/employeedashboard/emprequestTimeOff"
      name="Request Time Off"
      element={<ProtectedRoute element={<EmployeeRequestTimeOff />} />}
    />
    <Route
      path="/shortlistEmployeeform"
      name="Short List Employee Form"
      element={<ShortListEmployeeForm />}
    />
    <Route
      path="/interviewSchedule"
      name="Interview Schedule"
      element={<ProtectedRoute element={<InterviewSchedule />} />}
    />
    <Route
      path="/employeedashboard/emptrainingDetail/:trainingId"
      name="Training Detail"
      element={<ProtectedRoute element={<EmployeeTrainingDetail />} />}
    />
    <Route
      path="/employeedashboard/enrolledTrainingEmployee/:trainingId"
      name="Enrolled Training Employee"
      element={<ProtectedRoute element={<EnrolledTrainingEmployee />} />}
    />
    <Route
      path="/employeedashboard/empcomplaintDetail/:email"
      name="Complaint Detail"
      element={<ProtectedRoute element={<EmployeeComplaintDetail />} />}
    />
    <Route
      path="/employeedashboard/addEmployeeExpense"
      name="Add Employee Expense"
      element={<ProtectedRoute element={<AddEmployeeExpense />} />}
    />
    <Route
      path="/employeedashboard/employeeExpenseRequest"
      name="Employee Expense Request"
      element={<ProtectedRoute element={<EmployeeExpenseRequest />} />}
    />
    <Route
      path="/employeedashboard/emp-announcement-detail/:announcementId"
      element={<ProtectedRoute element={<EmployeeAnnouncementDetail />} />}
    />
  </React.Fragment>
);

export default EmployeeRoutes;
