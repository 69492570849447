import React from "react";
import { Navigate, Route } from "react-router-dom";
import RequestTimeOff from "Components/RequestTimeOff/RequesttimeOff";
import SignIn from "Components/authentication/sign-in";
import Personal from "Components/Personal/Personal";
import Job from "Components/Job/Job";
import Emergency from "Components/Emergency/Emergency";
import Documents from "Components/Documents/Documents";
import Benefits from "Components/Benefits/Benefits";
import Assets from "Components/Assets/Assets";
import Onboarding from "Components/Onboarding/Onboarding";
import Attendance from "Components/Attendence/Attendence";
import ViewCareer from "Components/View Career Page/ViewCareerPage";
import JobOpenings from "Components/View Career Page/JobOpenings";
import ViewCandidate from "Components/Hiring/ViewCandidate";
import EmployeeDetail from "Components/QualifiedEmployees/EmployeeDetail/EmployeeDetail";
import HumanResource from "Components/ParentComponent/ParentComponent";
import Terminate from "Components/QualifiedEmployees/Terminate/Terminate";
import ExpenseManagement from "Components/ExpenseManagment/index";
import ReportsAttendance from "Components/Report/ReportAttendance/ReportAttendence";
import TimeOff from "Components/Report/TimeOff/index";
import AddPolicy from "Components/Compliance/Complaince";
import HiringOnboarding from "Components/Hiring/HiringOnboarding/HiringOnboarding";
import TrainingDetail from "Components/TrainingAndDevelopement/TrainingDetails/TrainingDetail";
import ComplaintDetail from "Components/Complaints/ComplaintDetail";
import SalarySlip from "Components/Report/SalarySlip/SalarySlip";
import SkillsReport from "Components/Report/SkillsReport/SkillsReport";
import SalaryBaseJobData from "Components/Report/SalaryAnalysis/SalaryBaseJobData";
import EnrolledEmployeeForTraining from "Components/Report/EnrolledEmployee/EnrolledEmployeeForTraining";
import AnnouncementDetail from "Components/dashboard/components/Announcement/AnnuncementDetail";
import PerformanceManagementDetail from "Components/PerformanceManagement/PerformanceManagementDetail";
import DocumentManager from "Components/DocumentManager/DocumentManager";
import OfferLetter from "Components/dashboard/DocumentCardChild/OfferLetter/OfferLetter";
import SpecRecruitment from "Components/dashboard/DocumentCardChild/SpecRecruitment/SpecRecruitment";
import RecruitmentIntegration from "Components/dashboard/DocumentCardChild/RecruitmentIntegration/RecruitmentIntegration";
import OnboardingBackground from "Components/dashboard/DocumentCardChild/OnboardingBackground/OnboardingBackground";
import ProtectedHRRoute from "Components/authentication/ProtectedHRFile/ProtectedHRFile";
import CorrectiveActionForm from "Components/Complaints/CorrectiveActionForm";
import ViweCorrectiveDetail from "Components/Complaints/ViewCorrectiveComplaintAction";
import SoftwareInputFields from "Components/Assets/SoftwareInputFields";
import ChildManagerinputfields from "Components/ChildManager/ChildManagerinputfields";
import AssignRole from "Components/ChildManager/AssignRole";
import OrganizationHierarchy from "Components/Report/OrganizationHierarchy/OrganizationHierarchy";
import NotFound from "404NotFound";
import SoftwareAssets from "Components/Assets/SoftwareInputFields";
import HardwareAssets from "Components/Assets/HardwareInputFields";
import DocumentPreview from "Components/DocumentsStore/DocPreview";
const RedirectToHRDashboard = () => <Navigate to="/dashboard" />;
const HRRoutes = () => (
    <React.Fragment>
    <Route path="*" element={<NotFound />} />
    <Route path="/" element={<ProtectedHRRoute element={<RedirectToHRDashboard />} />} />
    <Route path="/signIn" name="Sign In" element={<SignIn />} />
    <Route path="/personal" name="Personal" element={<ProtectedHRRoute element={<Personal />} />} />
    <Route
      path="/childManager"
      name="Child Manager"
      element={<ProtectedHRRoute element={<ChildManagerinputfields />} />}
    />
    <Route path="/job" name="Job" element={<ProtectedHRRoute element={<Job />} />} />
    <Route
      path="/softwareAssets"
      name="Software Assets"
      element={<ProtectedHRRoute element={<SoftwareInputFields />} />}
    />
    <Route
      path="/emergency"
      name="Emergency"
      element={<ProtectedHRRoute element={<Emergency />} />}
    />
    <Route
      path="/assignRole/:childManagerId"
      name="AssignRole"
      element={<ProtectedHRRoute element={<AssignRole />} />}
    />

    <Route
      path="/documents"
      name="Documents"
      element={<ProtectedHRRoute element={<Documents />} />}
    />
    <Route
      path="/document-preview"
      name="Document-preview"
      element={<ProtectedHRRoute element={<DocumentPreview />} />}
    />
    <Route
      path="/documentsManagers"
      name="Document Manager"
      element={<ProtectedHRRoute element={<DocumentManager />} />}
    />
    <Route path="/benefits" name="Benefits" element={<ProtectedHRRoute element={<Benefits />} />} />
    <Route path="/assets" name="Assets" element={<ProtectedHRRoute element={<Assets />} />} />
    <Route
      path="/onboarding"
      name="On boarding"
      element={<ProtectedHRRoute element={<Onboarding />} />}
    />
    <Route
      path="/hiringOnboarding/:empId"
      name="On boarding"
      element={<ProtectedHRRoute element={<HiringOnboarding />} />}
    />
    <Route
      path="/attendance"
      name="Attendance"
      element={<ProtectedHRRoute element={<Attendance />} />}
    />
    <Route path="/viewcareer" name="View Career" element={<ViewCareer />} />
    <Route path="/jobOpenings/:postingJobId" name="JobOpenings" element={<JobOpenings />} />
    <Route
      path="/viewcandidate/:postingJobId"
      name="View Candidate"
      element={<ProtectedHRRoute element={<ViewCandidate />} />}
    />
    <Route
      path="/employeeDetail/:personalId"
      name="Employee Details"
      element={<ProtectedHRRoute element={<EmployeeDetail />} />}
    />
    <Route
      path="/requestTimeOff"
      name="Request Time Off"
      element={<ProtectedHRRoute element={<RequestTimeOff />} />}
    />
    <Route
      path="/humanResource/:empId"
      element={<ProtectedHRRoute element={<HumanResource />} />}
    />
    <Route
      path="/humanResource/:personalId"
      element={<ProtectedHRRoute element={<HumanResource />} />}
    />
    <Route
      path="/terminate/:personalId"
      name="Terminate"
      element={<ProtectedHRRoute element={<Terminate />} />}
    />
    <Route
      path="/trainingDetail/:trainingId"
      name="Training Detail"
      element={<ProtectedHRRoute element={<TrainingDetail />} />}
    />
    <Route
      path="/complaintDetail/:complaintId/:email"
      name="Complaint Detail"
      element={<ProtectedHRRoute element={<ComplaintDetail />} />}
    />
    <Route
      path="/expenseManagement"
      name="Expense Management"
      element={<ProtectedHRRoute element={<ExpenseManagement />} />}
    />
    <Route
      path="/addPolicy"
      name="Add Policy"
      element={<ProtectedHRRoute element={<AddPolicy />} />}
    />
    <Route
      path="/reportsAttendance"
      name="Reports Attendance"
      element={<ProtectedHRRoute element={<ReportsAttendance />} />}
    />
    <Route path="/timeOff" name="TimeOff" element={<ProtectedHRRoute element={<TimeOff />} />} />
    <Route
      path="/performance/:email"
      element={<ProtectedHRRoute element={<PerformanceManagementDetail />} />}
    />
    <Route
      path="/offerLetters"
      name="Offer Letter"
      element={<ProtectedHRRoute element={<OfferLetter />} />}
    />
    <Route
      path="/specRecruitment"
      name="Spec Recruitment"
      element={<ProtectedHRRoute element={<SpecRecruitment />} />}
    />
    <Route
      path="/recruitment-Integration"
      name="Recruitment Integration"
      element={<ProtectedHRRoute element={<RecruitmentIntegration />} />}
    />
    <Route
      path="/onboardingBackground"
      name="Onboarding Background"
      element={<ProtectedHRRoute element={<OnboardingBackground />} />}
    />
    <Route
      path="/salarySlip"
      name="Salary Slip"
      element={<ProtectedHRRoute element={<SalarySlip />} />}
    />
    <Route
      path="/enrolled-Employee-for-Training"
      name="Enrolled Training Employee"
      element={<ProtectedHRRoute element={<EnrolledEmployeeForTraining />} />}
    />

    <Route
      path="/announcement-detail/:announcementId"
      element={<ProtectedHRRoute element={<AnnouncementDetail />} />}
    />
    <Route path="/skillsReport" element={<ProtectedHRRoute element={<SkillsReport />} />} />
    <Route path="/salaryAnalysis" element={<ProtectedHRRoute element={<SalaryBaseJobData />} />} />

    <Route
      path="/corrective-action-form/:complaintId/:email"
      name="corrective-action-form"
      element={<ProtectedHRRoute element={<CorrectiveActionForm />} />}
    />
    <Route
      path="/view-corrective-action-form/:complaintId"
      name="view corrective-action-form"
      element={<ProtectedHRRoute element={<ViweCorrectiveDetail />} />}
    />
    <Route
      path="/organizationHierarchy"
      name="Organization Hierarchy"
      element={<ProtectedHRRoute element={<OrganizationHierarchy />} />}
    />
    <Route
      path="/software-assets"
      name="SoftwareAssets"
      element={<ProtectedHRRoute element={<SoftwareAssets />} />}
    />
    <Route
      path="/hardware-assets"
      name="hardwareAssets"
      element={<ProtectedHRRoute element={<HardwareAssets />} />}
    />
  </React.Fragment>
);

export default HRRoutes;
