import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Table, Card, Button } from "antd";
import SoftBox from "StyleFeatures/SoftBox";
import RecruitmentService from "./RecruitmentService";
import { useNavigate } from "react-router-dom";

function RecruitmentIntegration() {
  const {
    postingJobTitles,
    departments,
    handleJobTitleChange,
    handleDepartmentChange,
    handleMonthChange,
    employeeColumns,
    jobPostingColumns,
    selectedMonth,
    selectedDepartment,
    jobPostings,
    employees,
    monthNames,
  } = RecruitmentService();

  // State management for tabs
  const [activeTab, setActiveTab] = useState("positions");

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card
            title="Recruitment Integration"
            className="card-professional"
            extra={
              <Button type="default" onClick={back}>
                Back
              </Button>
            }
          >
            <div className="row">
              <div className="d-flex justify-content-center mb-3">
                <SoftBox
                  color={activeTab === "positions" ? "info" : "text"}
                  onClick={() => handleTabChange("positions")}
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    background:
                      activeTab === "positions" ? "linear-gradient(#FF5722, #FF8A00)" : "#f0f0f0",
                    marginRight: "20px",
                  }}
                >
                  <h6 style={{ textAlign: "center", color: "black" }}>Number of Positions</h6>
                </SoftBox>

                <SoftBox
                  color={activeTab === "employees" ? "info" : "text"}
                  onClick={() => handleTabChange("employees")}
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    background:
                      activeTab === "employees"
                        ? "linear-gradient(45deg, #FF5722, #FF8A00)"
                        : "#f0f0f0",
                  }}
                >
                  <h6 style={{ textAlign: "center", color: "black" }}>Number of Employees</h6>
                </SoftBox>
              </div>

              {activeTab === "positions" && (
                <>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="col-md-2 mb-3">
                      <select
                        className="form-select form-select-sm"
                        onChange={handleMonthChange}
                        value={selectedMonth}
                      >
                        <option value="">All Months</option>
                        {monthNames.map((month, index) => (
                          <option key={index} value={index}>
                            {month}
                          </option>
                        ))}
                      </select>
                    </div>
                    &nbsp;&nbsp;
                    <div className="mb-3" style={{ display: "inline-block" }}>
                      <select
                        className="form-select form-select-sm"
                        value={selectedDepartment}
                        onChange={handleDepartmentChange}
                      >
                        {departments.map((department) => (
                          <option key={department} value={department}>
                            {department}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <Table
                    className="table-responsive"
                    dataSource={jobPostings.filter(
                      (post) =>
                        (selectedDepartment === "All Departments" ||
                          post.department === selectedDepartment) &&
                        (!selectedMonth ||
                          new Date(post.createdOn).getMonth() === parseInt(selectedMonth, 10))
                    )}
                    columns={jobPostingColumns}
                    pagination={{ pageSize: 10 }}
                  />
                </>
              )}

              {activeTab === "employees" && (
                <>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="mb-3" style={{ display: "inline-block" }}>
                      <select
                        className="form-select form-select-sm"
                        onChange={handleJobTitleChange}
                      >
                        {postingJobTitles.map((postingJobTitle) => (
                          <option key={postingJobTitle} value={postingJobTitle}>
                            {postingJobTitle}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <Table
                    className="table-responsive"
                    dataSource={employees}
                    columns={employeeColumns}
                    pagination={{ pageSize: 10 }}
                  />
                </>
              )}
            </div>
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default RecruitmentIntegration;
