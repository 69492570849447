import React, { useState, useEffect } from "react";
import "./Leaves.scss";
import { useNavigate } from "react-router-dom";
import SoftButton from "StyleFeatures/SoftButton";
import axios from "axios";
import { Card, Empty } from "antd";

function Leaves() {
  const navigate = useNavigate();
  const [leaveData, setLeaveData] = useState([]);
  const [showAllLeaves, setShowAllLeaves] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const employeeEmail = localStorage.getItem("loginEmail");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/LeaveAddPolicy/Get-Enrolled-Employee-By-Email/${employeeEmail}`
        );
        setLeaveData(response.data);
      } catch (error) {
        console.error("Error fetching leave data:", error);
      }
    };

    if (employeeEmail) {
      fetchData();
    }
  }, [employeeEmail]);

  const requestTimeOff = () => {
    navigate("/employeedashboard/emprequestTimeOff");
  };

  const handleViewAll = () => {
    setShowAllLeaves(true);
  };

  const handleCloseAll = () => {
    setShowAllLeaves(false);
  };

  return (
    <Card title="Time Off" className="card-professional">
      {leaveData.length === 0 ? (
        <Empty description="Leaves not assigned yet!" />
      ) : (
        <div>
          <div className="row">
            {leaveData.slice(0, showAllLeaves ? leaveData.length : 4).map((item) => (
              <div key={item.e.enrolledEmpoyeeId} className="col-xl-3 col-lg-3">
                <div className="card cardpd">
                  <div className="cardpd-statistic-3 p-4">
                    <div className="mb-3">
                      <h6 className="card-title mb-0 text">{item.leaveAddPolicy.policyName}</h6>
                    </div>
                    <div className="mb-2">
                      <h4 className="card-title mb-0 text">
                        <i className="fa fa-home" aria-hidden="true"></i>&nbsp;
                        {item.leaveAddPolicy.grantedDays}
                      </h4>
                    </div>
                    <div className="mb-3">
                      <h6 className="card-title mb-0 text" style={{ fontSize: "small" }}>
                        Days Available
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-end">
              {!showAllLeaves && leaveData.length > 4 && (
                <SoftButton
                  variant="outlined"
                  style={{ marginRight: "10px" }}
                  color="info"
                  size="small"
                  onClick={handleViewAll}
                >
                  <i className="fa fa-arrow-down" aria-hidden="true"></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;View All
                </SoftButton>
              )}

              {showAllLeaves && (
                <SoftButton
                  variant="outlined"
                  style={{ marginRight: "10px" }}
                  color="info"
                  size="small"
                  onClick={handleCloseAll}
                >
                  <i className="fa fa-arrow-up" aria-hidden="true"></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;Close
                </SoftButton>
              )}
              <SoftButton variant="outlined" color="info" size="small" onClick={requestTimeOff}>
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                &nbsp;&nbsp;&nbsp;&nbsp;Request Time Off
              </SoftButton>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}

export default Leaves;
