import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import countryList from "react-select-country-list";
import SoftButton from "StyleFeatures/SoftButton";
import emergencyService from "../APIServices/PersonalInformationService";
import { useDispatch, useSelector } from "react-redux";
import { updateEmergencyInfo } from "../Services/Actions/actions";

import { CInputGroup } from "@coreui/react";

function Emergency({ onNext, onBack, employeeDetail }) {
  const personalData = useSelector((state) => state.personal);
  const jobData = useSelector((state) => state.job);
  const dispatch = useDispatch();

  const {
    emergencyFormData,
    emergencyFormDataError,
    setEmergencyFormData,
    handleEmergencyChange,
    setEmergencyFormDataError,
    validateEmergencyValidation,
  } = emergencyService();

  useEffect(() => {
    // Populate formData with employee data when editing
    if (employeeDetail) {
      const { emergency } = employeeDetail;
      setEmergencyFormData({
        ...emergencyFormData,
        personalId: emergency.personalId,
        Name: emergency.name,
        relationShip: emergency.relationShip,
        workPhone: emergency.workPhone,
        ext: emergency.ext,
        mobileNo: emergency.mobileNo,
        email: emergency.email,
        streetAddress1: emergency.streetAddress1,
        streetAddress2: emergency.streetAddress2,
        city: emergency.city,
        state: emergency.state,
        postalCode: emergency.postalCode,
        Country: emergency.country,
      });
    }
  }, [employeeDetail]);

  const handleNext = () => {
    const isValid = validateEmergencyValidation(emergencyFormData, setEmergencyFormDataError);
    if (isValid) {
      dispatch(updateEmergencyInfo(emergencyFormData));
      onNext();
    }
  };

  const handleDNext = () => {
    onNext();
  };

  const options = useMemo(() => countryList().getData(), []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await handleNext();
      if (success) {
        onNext();
        toast.success("Data has Been Added Successfully", {
          autoClose: 5000,
          style: { background: "lightgreen", color: "white" },
        });
      }
    } catch (error) {
      toast.error(error.message || "Added failed", {
        autoClose: 5000,
        style: { background: "lightcoral", color: "white" },
      });
    }
  };
  const handleBack = () => {
    onBack();
  };
  return (
    <div className="card">
      <div className="card-header" style={{ position: "relative" }}>
        <h5>Emergency</h5>
      </div>
      <div className="card-body">
        <form onSubmit={onSubmit} encType="multipart/form-data">
          <div className="row">
            <label className="mb-3" style={{ textAlign: "left" }}>
              <i className="fa fa-phone-square" aria-hidden="true"></i>&nbsp;CONTACT
            </label>
            <input
                  type="hidden"
                  value={emergencyFormData.personalId}
                  className="form-control"
                  onChange={handleEmergencyChange}
                />
            <div className="col-md-5 mb-3">
              <div className="mb-3">
                <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
                  Name *
                </label>
                <input
                  type="text"
                  name="Name"
                  value={emergencyFormData.Name}
                  onChange={handleEmergencyChange}
                  className={`form-control ${emergencyFormDataError.NameError ? "is-invalid" : ""}`}
                  data-section="emergency"
                  placeholder="Enter name here"
                />
                {emergencyFormDataError.NameError && (
                  <div className="invalid-feedback invalid-message">
                    {emergencyFormDataError.NameError}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  RelationShip *
                </label>
                <select
                  aria-label="Default select example"
                  name="relationShip"
                  value={emergencyFormData.relationShip}
                  onChange={handleEmergencyChange}
                  className={`form-select ${
                    emergencyFormDataError.relationShipError ? "is-invalid" : ""
                  }`}
                >
                  <option value=""> Please Select </option>
                  <option value="Father"> Father </option>
                  <option value="Mother "> Mother </option>
                  <option value="Siblings "> Siblings </option>
                  <option value="Spouse "> Spouse </option>
                </select>

                {emergencyFormDataError.relationShipError && (
                  <div className="invalid-feedback invalid-message">
                    {emergencyFormDataError.relationShipError}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-5 mb-3">
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "large" }}
                >
                  Work Phone
                </label>
                <CInputGroup className="mb-3">
                  <PhoneInput
                    className={`col-md-12 ${
                      emergencyFormDataError.workPhoneError ? "is-invalid" : ""
                    }`}
                    data-section="emergency"
                    defaultCountry="us"
                    inputStyle={{ width: "310px" }}
                    name="workPhone"
                    value={emergencyFormData.workPhone}
                    onChange={(value) => handleEmergencyChange(value, "workPhone")}
                    onBlur={handleEmergencyChange}
                  />
                  {emergencyFormDataError.workPhoneError && (
                    <div className="invalid-feedback invalid-message">
                      {emergencyFormDataError.workPhoneError}
                    </div>
                  )}
                </CInputGroup>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="mb-3">
                <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
                  EXT *
                </label>
                <input
                  type="number"
                  name="ext"
                  value={emergencyFormData.ext}
                  onChange={handleEmergencyChange}
                  className={`form-control ${emergencyFormDataError.extError ? "is-invalid" : ""}`}
                  data-section="emergency"
                  placeholder="Enter EXT here"
                />
                {emergencyFormDataError.extError && (
                  <div className="invalid-feedback invalid-message">
                    {emergencyFormDataError.extError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-3">
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "large" }}
                >
                  Mobile Phone
                </label>
                <CInputGroup className="mb-3">
                  <PhoneInput
                    className={`col-md-12 ${
                      emergencyFormDataError.mobileNoError ? "is-invalid" : ""
                    }`}
                    data-section="emergency"
                    defaultCountry="us"
                    inputStyle={{ width: "840px" }}
                    name="mobileNo"
                    value={emergencyFormData.mobileNo}
                    onChange={(value) => handleEmergencyChange(value, "mobileNo")}
                    onBlur={handleEmergencyChange}
                  />
                  {emergencyFormDataError.mobileNoError && (
                    <div className="invalid-feedback invalid-message">
                      {emergencyFormDataError.mobileNoError}
                    </div>
                  )}
                </CInputGroup>
              </div>
            </div>
            <div className="col-md-9 mb-3">
              <div className="mb-3">
                <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
                  Email *
                </label>
                <input
                  type="text"
                  name="email"
                  value={emergencyFormData.email}
                  onChange={handleEmergencyChange}
                  className={`form-control ${
                    emergencyFormDataError.emailError ? "is-invalid" : ""
                  }`}
                  data-section="emergency"
                  placeholder="Enter email here"
                />
                {emergencyFormDataError.emailError && (
                  <div className="invalid-feedback invalid-message">
                    {emergencyFormDataError.emailError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <label className="mb-3" style={{ textAlign: "left" }}>
              <i className="fa fa-home" aria-hidden="true"></i>&nbsp;ADDRESS
            </label>

            <div className="col-md-9 mb-3">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Street Address 1
                </label>
                <input
                  type="text"
                  name="streetAddress1"
                  value={emergencyFormData.streetAddress1}
                  onChange={handleEmergencyChange}
                  className={`form-control ${
                    emergencyFormDataError.streetAddress1Error ? "is-invalid" : ""
                  }`}
                  data-section="emergency"
                  placeholder="Enter Street Address 1 here"
                />
                {emergencyFormDataError.streetAddress1Error && (
                  <div className="invalid-feedback invalid-message">
                    {emergencyFormDataError.streetAddress1Error}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-3">
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Street Address 2
                </label>
                <input
                  type="text"
                  name="streetAddress2"
                  value={emergencyFormData.streetAddress2}
                  onChange={handleEmergencyChange}
                  className={`form-control ${
                    emergencyFormDataError.streetAddress2Error ? "is-invalid" : ""
                  }`}
                  data-section="emergency"
                  placeholder="Enter Street Address 2 here"
                />
                {emergencyFormDataError.streetAddress2Error && (
                  <div className="invalid-feedback invalid-message">
                    {emergencyFormDataError.streetAddress2Error}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-3">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={emergencyFormData.city}
                    onChange={handleEmergencyChange}
                    className={`form-control ${
                      emergencyFormDataError.cityError ? "is-invalid" : ""
                    }`}
                    data-section="emergency"
                    placeholder="Enter City here"
                  />
                  {emergencyFormDataError.cityError && (
                    <div className="invalid-feedback invalid-message">
                      {emergencyFormDataError.cityError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    State
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      emergencyFormDataError.stateError ? "is-invalid" : ""
                    }`}
                    data-section="emergency"
                    placeholder="Enter State / Province here"
                    name="state"
                    value={emergencyFormData.state}
                    onChange={handleEmergencyChange}
                  />
                  {emergencyFormDataError.stateError && (
                    <div className="invalid-feedback invalid-message">
                      {emergencyFormDataError.stateError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Postal / Zip Code
                  </label>
                  <input
                    type="text"
                    name="postalCode"
                    value={emergencyFormData.postalCode}
                    onChange={handleEmergencyChange}
                    className={`form-control ${
                      emergencyFormDataError.postalCodeError ? "is-invalid" : ""
                    }`}
                    data-section="emergency"
                    placeholder="Enter Postal / Zip Code here"
                  />
                  {emergencyFormDataError.postalCodeError && (
                    <div className="invalid-feedback invalid-message">
                      {emergencyFormDataError.postalCodeError}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-9 mb-3">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
                style={{ fontSize: "medium" }}
              >
                Country
              </label>
              <CInputGroup className="mb-3">
                <select
                  defaultValue="US"
                  aria-label=".form-select-sm example"
                  name="Country"
                  value={emergencyFormData.Country}
                  onChange={handleEmergencyChange}
                  className={`form-control ${
                    emergencyFormDataError.CountryError ? "is-invalid" : ""
                  }`}
                  data-section="emergency"
                >
                  <option value="">Please select</option>
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {emergencyFormDataError.CountryError && (
                  <div className="invalid-feedback invalid-message">
                    {emergencyFormDataError.CountryError}
                  </div>
                )}
              </CInputGroup>
            </div>
            <div className="col-md-12">
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end" }}
                color="info"
                size="small"
                type="Submit"
                onClick={handleNext}
                // onClick={handleDNext}
              >
                Next
              </SoftButton>
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end", marginRight: "10px" }}
                color="black"
                size="small"
                onClick={handleBack}
              >
                Back
              </SoftButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
Emergency.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  employeeDetail: PropTypes.func.isRequired,
};
export default Emergency;
