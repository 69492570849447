import { Button, Card, Table } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "StyleFeatures/SoftButton";
import SoftBox from "StyleFeatures/SoftBox";
import Footer from "Components/authentication/components/Footer";
import Service from "./Service";
import { useNavigate } from "react-router-dom";

function EnrolledEmployeeForTraining() {
  const {
    columns,
    filteredDataSource,
    handleDownloadReport,
    handleTrainingNameChange,
    trainingNames,
    selectedTrainingName,
  } = Service();

  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        className="card-professional"
        title="Enrolled Employees for training and Development"
        extra={
          <Button type="default" onClick={back}>
            Back
          </Button>
        }
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SoftBox color="text" style={{ marginRight: "20px" }}>
            <div style={{ display: "inline-block" }}>
              <Button type="primary" onClick={handleDownloadReport}>
                <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Employee Reports
              </Button>
            </div>
          </SoftBox>
          <div className="mb-3" style={{ display: "inline-block" }}>
            <select
              className="form-select form-select-sm"
              value={selectedTrainingName}
              onChange={handleTrainingNameChange}
            >
              <option value="All Training Detail">All Training Detail</option>
              {trainingNames.map((training) => (
                <option key={training} value={training}>
                  {training}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <Table
            className="table-responsive"
            bordered
            dataSource={filteredDataSource}
            columns={columns}
            pagination={{ pageSize: 10 }}
          />
        </div>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default EnrolledEmployeeForTraining;
