import React from "react";
import { Form, Input, Button, Select, Card, Row, Col, Table } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "Components/authentication/components/Footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import HiringFunctions from "./HiringApiFunction";

const { Option } = Select;

const JobPostingForm = () => {
  const {
    form,
    jobPostings,
    childManagers,
    showForm,
    loading,
    addloading,
    onFinish,
    columns,
    setShowForm,
    recruiter,
    jobDescription,
    setJobDescription,
    editingJobId,
    isCompensationEnabled,
    handleBudgetApprovalChange,
    usStates,
  } = HiringFunctions();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        className="card-professional"
        title="Job Postings"
        extra={
          <Link to="/viewcareer" className="underline-link">
            <div style={{ textDecoration: "underline", color:'white' }}>View Career Page</div>
          </Link>
        }
      >
        <Button type="primary" onClick={() => setShowForm(!showForm)} style={{ marginBottom: 20 }}>
          {showForm ? "Back to Job List" : "Add Job Posting"}
        </Button>

        {!showForm ? (
          <Table
            className="table-responsive"
            columns={columns}
            loading={loading}
            dataSource={jobPostings}
            rowKey="key"
          />
        ) : (
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="postingJobTitle"
                  label="Job Title"
                  rules={[{ required: true, message: "Please input the job title!" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="budgetApproved"
                  label="Budget Approved"
                  rules={[
                    {
                      required: true,
                      message: "Please select whether the budget is approved!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select budget approval"
                    onChange={handleBudgetApprovalChange}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="jobStatus"
                  label="Job Status"
                  rules={[{ required: true, message: "Please select job status!" }]}
                >
                  <Select placeholder="Select job status">
                    <Option value="Draft">Draft</Option>
                    <Option value="Open">Open</Option>
                    <Option value="On Hold">On Hold</Option>
                    <Option value="Filled">Filled</Option>
                    <Option value="Canceled">Canceled</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="recruiter"
                  label="Recruiter"
                  rules={[{ required: true, message: "Please input the recruiter name!" }]}
                >
                  <Input value={recruiter} disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="hiringLead"
                  label="Hiring Lead"
                  rules={[{ required: true, message: "Please select the hiring lead!" }]}
                >
                  <Select placeholder="Select hiring lead">
                    <Option value={recruiter}>{recruiter} HR Manager</Option>
                    {childManagers.length > 0 ? (
                      childManagers.map((manager, index) => (
                        <Option key={index} value={manager.name}>
                          {manager.name} Associate Manager
                        </Option>
                      ))
                    ) : (
                      <Option disabled>No Associate managers available</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="department"
                  label="Department"
                  rules={[{ required: true, message: "Please select the department!" }]}
                >
                  <Select placeholder="Select department">
                    <Option value="IT">IT</Option>
                    <Option value="Customer Success">Customer Success</Option>
                    <Option value="Finance">Finance</Option>
                    <Option value="Human Resources">Human Resources</Option>
                    <Option value="Marketing">Marketing</Option>
                    <Option value="Operations">Operations</Option>
                    <Option value="Product">Product</Option>
                    <Option value="Sales">Sales</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="employementType"
                  label="Employment Type"
                  rules={[{ required: true, message: "Please select employment type!" }]}
                >
                  <Select placeholder="Select employment type">
                    <Option value="Contractor">Contractor</Option>
                    <Option value="Full-time">Full-time</Option>
                    <Option value="Intern">Intern</Option>
                    <Option value="Part-time">Part-time</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="maximumExperience"
                  label="Maximum Experience (years)"
                  rules={[{ required: true, message: "Please select maximum experience!" }]}
                >
                  <Select placeholder="Select experience level">
                    <Option value="Entry-Level (1-2 years)">Entry-Level (1-2 years)</Option>
                    <Option value="Mid-Level (2-3 years)">Mid-Level (2-3 years)</Option>
                    <Option value="Experienced (3-5 years)">Experienced (3-5 years)</Option>
                    <Option value="Manager/Supervisor (5-6 years)">
                      Manager/Supervisor (5-6 years)
                    </Option>
                    <Option value="Senior Manager/Supervisor (6-7 years)">
                      Senior Manager/Supervisor (6-7 years)
                    </Option>
                    <Option value="Executive (7-8 years)">Executive (7-8 years)</Option>
                    <Option value="Senior Executive (8-10 years)">
                      Senior Executive (8-10 years)
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="compensation"
                  label="Compensation"
                  rules={[{ required: true, message: "Please input the compensation!" }]}
                >
                  {/* <Input disabled={!isCompensationEnabled} /> */}

                  <Select disabled={!isCompensationEnabled} placeholder="Select Compensation">
                    <Option value="$0-$10000">$0-$10000</Option>
                    <Option value="$10000-$20000">$10000-$20000</Option>
                    <Option value="$20000-$30000">$20000-$30000</Option>
                    <Option value="$30000-$40000">$30000-$40000</Option>
                    <Option value="$40000-$50000">$40000-$50000</Option>
                    <Option value="$50000-$60000">$50000-$60000</Option>
                    <Option value="$60000-$70000">$60000-$70000</Option>
                    <Option value="$70000-$80000">$70000-$80000</Option>
                    <Option value="$80000-$90000">$80000-$90000</Option>
                    <Option value="$90000-$100000">$90000-$100000</Option>
                    <Option value="$100000-$110000">$100000-$110000</Option>
                    <Option value="$110000-$120000">$110000-$120000</Option>
                    <Option value="$120000-$130000">$120000-$130000</Option>
                    <Option value="$130000-$140000">$130000-$140000</Option>
                    <Option value="$140000-$150000">$140000-$150000</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="jobType"
                  label="Job Type"
                  rules={[{ required: true, message: "Please select job type!" }]}
                >
                  <Select placeholder="Select job type">
                    <Option value="On-site">On-site</Option>
                    <Option value="Remote">Remote</Option>
                    <Option value="Hybrid">Hybrid</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="jobLocationType"
                  label="Job Location Type"
                  rules={[{ required: true, message: "Please select job location type!" }]}
                >
                  <Select placeholder="Select job location type">
                    {usStates.map((state) => (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="jobInternalCode"
                  label="Job Internal Code"
                  rules={[{ required: true, message: "Please input the job internal code!" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="skills"
              label="Skills"
              rules={[{ required: true, message: "Skill is required!" }]}
            >
              <Select mode="tags" placeholder="Add skills" />
            </Form.Item>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Job Description">
                  <ReactQuill
                    theme="snow"
                    placeholder="Enter job Description"
                    value={jobDescription}
                    onChange={setJobDescription}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={addloading} disabled={addloading}>
                {addloading
                  ? editingJobId
                    ? "Updating..."
                    : "Submitting..."
                  : editingJobId
                  ? "Update Job Posting"
                  : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
};

export default JobPostingForm;
