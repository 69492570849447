import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Job.scss";
import "react-datepicker/dist/react-datepicker.css";
import SoftButton from "StyleFeatures/SoftButton";
import jobService from "../APIServices/jobService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Footer from "Components/authentication/components/Footer";
import { Card } from "antd";

function JobS({ onNext, onBack, employeeDetail }) {
  const {
    onSubmit,
    isLoading,
    jobFormData,
    setJobFormData,
    jobFormDataError,
    handleNext,
    handleJobChange,
  } = jobService();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    // Populate formData with employee data when editing
    if (employeeDetail) {
      const { job } = employeeDetail;
      setJobFormData({
        ...jobFormData,
        personalId: job.personalId,
        forJobApply: job.forJobApply,
        hiringDate: job.hiringDate,
        team: job.team,
        manager: job.manager,
        probationStartDate: job.probationStartDate,
        probationEndDate: job.probationEndDate,
      });
    }
    fetchEmployees();
  }, [employeeDetail]);

  const handleBack = () => {
    onBack();
  };

  async function fetchEmployees() {
    try {
      const response = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All`);
      const responseData = response.data;
      const personalInfo = responseData.personalInfo;

      const updatedRows = personalInfo.map((info) => ({
        firstName: info.firstName && info.lastName ? `${info.firstName} ${info.lastName}` : null,
        email: info.email,
      }));

      setEmployeeDetails(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleManagerChange = (e) => {
    const selectedManager = e.target.value;
    let managerEmail = "";

    if (selectedManager === userName) {
      // If selected manager is the HR Manager
      managerEmail = "datagonomixhr@gmail.com";
    } else {
      const selectedManagerDetails = employeeDetails.find(
        (employee) => employee.firstName === selectedManager
      );
      managerEmail = selectedManagerDetails ? selectedManagerDetails.email : "";
    }

    // Update the form data with the selected manager and their email
    setJobFormData({
      ...jobFormData,
      manager: selectedManager,
      managerEmail: managerEmail,
    });
  };

  useEffect(() => {
    const email = localStorage.getItem("hremail");
    if (email) {
      fetchUserNameByEmail(email);
    }
  }, []);

  async function fetchUserNameByEmail(email) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/Auth/GetUserByEmail/${encodeURIComponent(email)}`
      );
      if (response.status === 200) {
        const { userName } = response.data;
        setUserName(userName); // Update the state with the fetched username
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <div className="body flex-grow-1 px-3">
      <Card title="Job" className="card-professional">
        <div className="row">
          {/* <form onSubmit={handleSubmit}> */}
          <ToastContainer />
          <form onSubmit={onSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-4">
                <input
                  type="hidden"
                  value={jobFormData.personalId}
                  className="form-control"
                  onChange={handleJobChange}
                />
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Job Title
                  </label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    name="forJobApply"
                    value={jobFormData.forJobApply}
                    onChange={handleJobChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <input
                  type="hidden"
                  value={jobFormData.salary}
                  className="form-control"
                  onChange={handleJobChange}
                />
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Salary
                  </label>
                  <input
                    type="text"
                    name="salary"
                    value={jobFormData.salary}
                    onChange={handleJobChange}
                    placeholder="Enter salary for job"
                    className={`form-control ${jobFormDataError.salaryError ? "is-invalid" : ""}`}
                  />
                  {jobFormDataError.salaryError && (
                    <div className="invalid-feedback invalid-message">
                      {jobFormDataError.salaryError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Hiring Date
                  </label>
                  <input
                    type="date"
                    className={`form-control ${
                      jobFormDataError.hiringDateError ? "is-invalid" : ""
                    }`}
                    name="hiringDate"
                    value={jobFormData.hiringDate}
                    onChange={handleJobChange}
                  />
                  {jobFormDataError.hiringDateError && (
                    <div className="invalid-feedback invalid-message">
                      {jobFormDataError.hiringDateError}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Department *
                  </label>
                  <input
                    type="text"
                    disabled
                    className={`form-control ${jobFormDataError.teamError ? "is-invalid" : ""}`}
                    placeholder="Enter team here"
                    value={jobFormData.team}
                    name="team"
                    onChange={handleJobChange}
                  />
                  {jobFormDataError.teamError && (
                    <div className="invalid-feedback invalid-message">
                      {jobFormDataError.teamError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Manager *
                  </label>
                  <select
                    aria-label="Default select example"
                    value={jobFormData.manager}
                    name="manager"
                    onChange={handleManagerChange}
                    className={`form-select ${jobFormDataError.managerError ? "is-invalid" : ""}`}
                  >
                    <option value="">Select Manager</option>
                    {employeeDetails.map((employee, index) => (
                      <option key={index} value={employee.firstName}>
                        {employee.firstName ? employee.firstName : "No Name Available"}
                      </option>
                    ))}
                    {userName && <option value={userName}>{userName} HR Manager</option>}
                  </select>
                  {jobFormDataError.managerError && (
                    <div className="invalid-feedback invalid-message">
                      {jobFormDataError.managerError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <input
                  type="hidden"
                  value={jobFormData.managerEmail}
                  className="form-control"
                  onChange={handleJobChange}
                />
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Manager Email
                  </label>
                  <input
                    type="text"
                    name="managerEmail"
                    value={jobFormData.managerEmail} // Manager email comes from state
                    onChange={handleJobChange}
                    className="form-control"
                    disabled // Disable the input field
                  />
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Probation Start Date
                  </label>
                  <input
                    type="date"
                    className={`form-control ${
                      jobFormDataError.probationStartDateError ? "is-invalid" : ""
                    }`}
                    name="probationStartDate"
                    value={jobFormData.probationStartDate}
                    onChange={handleJobChange}
                  />
                  {jobFormDataError.probationStartDateError && (
                    <div className="invalid-feedback invalid-message">
                      {jobFormDataError.probationStartDateError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Probation End Date
                  </label>
                  <input
                    type="date"
                    className={`form-control ${
                      jobFormDataError.probationEndDateError ? "is-invalid" : ""
                    }`}
                    name="probationEndDate"
                    value={jobFormData.probationEndDate}
                    onChange={handleJobChange}
                  />
                  {jobFormDataError.probationEndDateError && (
                    <div className="invalid-feedback invalid-message">
                      {jobFormDataError.probationEndDateError}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-12">
          <SoftButton
            variant="outlined"
            style={{ float: "inline-end" }}
            color="info"
            size="small"
            onClick={handleNext}
          >
            {isLoading ? "Submitting..." : "Submit"}
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </SoftButton>
          <SoftButton
            variant="outlined"
            style={{ float: "inline-end", marginRight: "10px" }}
            color="black"
            size="small"
            onClick={handleBack}
          >
            Back
          </SoftButton>
        </div>
      </Card>
      <Footer />
    </div>
  );
}

JobS.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  personalData: PropTypes.object.isRequired,
  employeeDetail: PropTypes.func.isRequired,
};

export default JobS;
