import "./ViewCareer.scss";
import JobOpeningService from "Components/APIServices/JopOpeningService";

function JobSideDetail() {
  const { jobDetails } = JobOpeningService();
  // Assign job details based on job nam
  return (
    <>
      {jobDetails && (
          <div className="card-below-content">
            <div className="detail">
              <p className="location">Location</p>
              <p className="loc-city">{jobDetails.postingInfo.jobLocationType}</p>
              <hr className="hr-viewcareer" style={{ marginTop: "1px" }}></hr>
            </div>
            <div className="detail">
              <p className="location">Department</p>
              <p className="loc-city">{jobDetails.postingInfo.department}</p>
              <hr className="hr-viewcareer" style={{ marginTop: "1px" }}></hr>
            </div>
            <div className="detail">
              <p className="location">Employment Type</p>
              <p className="loc-city">{jobDetails.postingInfo.employementType}</p>
              <hr className="hr-viewcareer" style={{ marginTop: "1px" }}></hr>
            </div>
            <div className="detail">
              <p className="location">Minimum Experience</p>
              <p className="loc-city">{jobDetails.postingInfo.maximumExperience}</p>
              <hr className="hr-viewcareer" style={{ marginTop: "1px" }}></hr>
            </div>
            <div className="detail">
              <p className="location">Compensation</p>
              <p className="loc-city">{jobDetails.postingInfo.compensation}</p>
              <hr className="hr-viewcareer" style={{ marginTop: "1px" }}></hr>
            </div>
          </div>
      )}
    </>
  );
}

export default JobSideDetail;
