import React, { useState, useEffect } from "react";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./RequesttimeOff.scss";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import SoftButton from "StyleFeatures/SoftButton";
import RequesttimeOffService from "EmployeeDashboard/EmployeeApiService/RequestTimeOffService";
import { Table } from "antd";
import axios from "axios";

function EmployeeRequestTimeOff() {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const {
    formData,
    formDataError,
    handleChange,
    handleSubmit,
    policies,
    show,
    handleShow,
    handleCancel,
    dataSource,
  } = RequesttimeOffService();

  const dashboard = () => {
    navigate("/employeedashboard");
  };

  const columns = [
    {
      title: "Request From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Request To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "For Requested Days",
      dataIndex: "amountDays",
      key: "amountDays",
    },
    {
      title: "For Request",
      dataIndex: "timeOffCategory",
      key: "timeOffCategory",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        switch (status) {
          case 0:
            return "Pending";
          case 1:
            return "Approved";
          case 2:
            return "Declined";
          default:
            return "";
        }
      },
    },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Request Time Off</h5>
              <button
                className="btn btn-outline-dark"
                style={{ float: "inline-end", marginTop: "-35px" }}
                onClick={dashboard}
              >
                Back
              </button>
            </div>
            <div className="card-body">
              {show && (
                <div className="row">
                  <div className="col-md-5 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        From *
                      </label>
                      <br></br>
                      <input
                        type="date"
                        name="from"
                        value={formData.from}
                        onChange={handleChange}
                        className={`form-control ${formDataError.fromError ? "is-invalid" : ""}`}
                      />
                      {formDataError.fromError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.fromError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        To *
                      </label>
                      <br />
                      <input
                        type="date"
                        name="to"
                        value={formData.to}
                        onChange={handleChange}
                        className={`form-control ${formDataError.toError ? "is-invalid" : ""}`}
                      />
                      {formDataError.toError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.toError}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Time Off Category *
                      </label>
                      <select
                        name="timeOffCategory"
                        value={formData.timeOffCategory}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.timeOffCategoryError ? "is-invalid" : ""
                        }`}
                      >
                        <option value=""> Please Select </option>
                        {policies.map((policy) => (
                          <option key={policy.leavepolicyId} value={policy.policyName}>
                            {policy.policyName}
                          </option>
                        ))}
                      </select>
                      {formDataError.timeOffCategoryError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.timeOffCategoryError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-2 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Granted Days *
                      </label>
                      <input
                        type="text"
                        placeholder="Days"
                        className="form-control"
                        value={formData.grantedDays}
                        onChange={handleChange}
                        disabled
                      />
                      {formDataError.grantedDaysError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.grantedDaysError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-2 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Amount Days *
                      </label>
                      <input
                        type="text"
                        placeholder="Days"
                        className="form-control"
                        onChange={handleChange}
                        value={formData.amountDays}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Notes
                      </label>
                      <textarea
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Type here..."
                        name="Notes"
                        value={formData.Notes}
                        onChange={handleChange}
                        className={`form-control ${formDataError.NotesError ? "is-invalid" : ""}`}
                      ></textarea>
                      {formDataError.NotesError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.NotesError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-9">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                      onClick={handleSubmit}
                    >
                      Send Request
                    </SoftButton>
                    <SoftButton
                      style={{ float: "inline-end", marginRight: "10px" }}
                      variant="outlined"
                      color="dark"
                      size="small"
                      onClick={handleCancel}
                    >
                      Cancel
                    </SoftButton>
                  </div>
                </div>
              )}
              {!show && (
                <>
                  <div className="col-md-12 mb-3">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end", marginBottom: "10px" }}
                      color="info"
                      size="small"
                      onClick={handleShow}
                    >
                      Send Leave Request
                    </SoftButton>
                  </div>
                  <Table
                    className="table-responsive"
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeRequestTimeOff;
