import { Button, Card, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Footer from "Components/authentication/components/Footer";

function ChildManager() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "childManagerId",
      key: "childManagerId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("MMMM D, YYYY h:mm A"),
    },
    {
      title: "Assign_Role",
      dataIndex: "Assign_Role",
      key: "Assign_Role",
      render: (_, record) => {
        return (
          <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Link to={`/assignRole/${record.childManagerId}`}>
              <EyeOutlined className="eyeOutlined" style={{ marginLeft: "10px" }} />
            </Link>
          </span>
        );
      },
    },
  ];

  const [dataSource, setDataSource] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/ChildManager/GetAllChildManagers`);
      setDataSource(response.data);
    } catch (error) {
      console.error("Error fetching child managers:", error);
    }
  };

  const manager = () => {
    navigate("/childManager");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card title="Associate Managers" className="card-professional">
        <Button type="primary" style={{ float: "right", marginBottom: "10px" }} onClick={manager}>
          Add New Manager
        </Button>
        <Table
          className="table-responsive"
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={{ pageSize: 10 }}
        />
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default ChildManager;
