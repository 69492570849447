import { useState, useEffect } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import data from "Components/Report/TimeOff/EmployeeOnLeave/EmpLeaveTable";

function EmployeeOnLeaveService() {
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState("All Departments");
    const [filteredRows, setFilteredRows] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { columns, rows } = data();
  
    useEffect(() => {
      const fetchDepartments = async () => {
        try {
          const response = await axios.get(`${apiUrl}/api/AttendanceReport/Get-All-Departments`);
          const departmentNames = response.data.map((dept) => dept.department);
          setDepartments(departmentNames);
        } catch (error) {
          console.error("Error fetching departments:", error);
        }
      };
  
      fetchDepartments();
    }, [apiUrl]);
  
    useEffect(() => {
      const extractDepartmentName = (departmentElement) => {
        return departmentElement.props.children;
      };
  
      if (selectedDepartment === "All Departments") {
        setFilteredRows(rows);
      } else {
        const filtered = rows.filter(
          (row) => extractDepartmentName(row.Department) === selectedDepartment
        );
        setFilteredRows(filtered);
      }
    }, [selectedDepartment, rows]);
  
    const handleDepartmentChange = (value) => {
      setSelectedDepartment(value);
    };
  
    const handleDownloadReport = () => {
      const filteredData = rows.filter(
        (row) =>
          selectedDepartment === "All Departments" ||
          row.Department.props.children === selectedDepartment
      );
  
      const doc = new jsPDF();
  
      doc.setFontSize(18);
      doc.text("Employee Leave Report", 14, 22);
  
      const lineY = 28;
      const lineWidth = 0.5;
      const lineColor = [192, 192, 192];
      doc.setLineWidth(lineWidth);
      doc.setDrawColor(...lineColor);
      doc.line(14, lineY, 200, lineY);
  
      const marginTop = 3;
      const marginTopEOM = 2;
      doc.setFontSize(12);
      doc.text("Employee: All - All employees selected", 14, 32 + marginTop);
  
      // Get the current date
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString();
  
      doc.text(`Report Date: ${formattedDate}`, 14, 42 + marginTopEOM);
      doc.text("Exceptions: No Deletions", 14, 52);
      doc.text(`Department: ${selectedDepartment}`, 14, 59);
  
      const headers = [
        ["Employee Name", "Email", "Department", "Days Off", "From", "To", "Leave Policy", "Status"],
      ];
      const data = filteredData.map((row) => {
        const employeeName = row.Employee.props.name;
        const employeeEmail = row.Employee.props.email;
        const department = row.Department.props.children || "N/A";
        const daysOff = row.Days_Off.props.children || "N/A";
        const fromDate = row.From.props.children || "N/A";
        const toDate = row.To.props.children || "N/A";
        const leavePolicy = row.Leave_Policy.props.children || "N/A";
        const status = row.status.props.children || "N/A";
  
        return [
          employeeName,
          employeeEmail,
          department,
          daysOff,
          fromDate,
          toDate,
          leavePolicy,
          status,
        ];
      });
  
      doc.autoTable({
        startY: 62,
        head: headers,
        body: data,
        headerStyles: {
          fillColor: [192, 192, 192],
          fontSize: 8,
        },
        styles: {
          fontSize: 8,
        },
      });
  
      doc.save(`employee_leave_report_${selectedDepartment}.pdf`);
    };

  return {
    columns,
    filteredRows,
    handleDownloadReport,
    handleDepartmentChange,
    departments,
    selectedDepartment
  };
}

export default EmployeeOnLeaveService;
