import React from "react";
import { Card, Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import {
  SmileOutlined,
  SyncOutlined,
  HeartOutlined,
  MessageOutlined,
  FilePdfOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

export default function DocumentCards() {
  return (
    <div style={{ padding: "10px" }}>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/specRecruitment" className="card-link">
            <Card className="document-card first-card">
              <div className="ribbon-content">
                <SmileOutlined className="icon" />
                <div className="card-content">
                  <Text strong>Spec recruiting</Text>
                  <p>Job stats overview</p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/recruitment-Integration" className="card-link">
            <Card className="document-card second-card">
              <div className="ribbon-content">
                <SyncOutlined className="icon" />
                <div className="card-content">
                  <Text strong>Sync Recruiting</Text>
                  <p>Sync recruitment process</p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/onboardingBackground" className="card-link">
            <Card className="document-card third-card">
              <div className="ribbon-content">
                <HeartOutlined className="icon" />
                <div className="card-content">
                  <Text strong>Background checks</Text>
                  <p>Candidate screening risks</p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/offerLetters" className="card-link">
            <Card className="document-card fourth-card">
              <div className="ribbon-content">
                <MessageOutlined className="message-icon" />
                <div className="card-content">
                  <Text strong>Offer Letters</Text>
                  <p>Compile offer letters</p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/onboarding" className="card-link">
            <Card className="document-card fifth-card">
              <div className="ribbon-content">
                <FilePdfOutlined className="icon" />
                <div className="card-content">
                  <Text strong>Onboarding checklists</Text>
                  <p>Onboarding checklists view</p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/documentsManagers" className="card-link">
            <Card className="document-card sixth-card">
              <div className="ribbon-content">
                <FileSyncOutlined className="icon" />
                <div className="card-content">
                  <Text strong>Document manager</Text>
                  <p>Document management system</p>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  );
}
