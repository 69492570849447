import React, { useEffect, useState } from "react";
import axios from "axios";
import { Space, Table, Collapse, Card } from "antd";
import { FileOpenOutlined } from "@mui/icons-material";
import Footer from "Components/authentication/components/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const { Panel } = Collapse;

function DocumentManager() {
  const [items, setItems] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/EmployeeInterview/GetReceiveContract`);
      if (!response.data.contract || !Array.isArray(response.data.contract)) {
        throw new Error("Invalid contract data format");
      }

      const contracts = response.data.contract;
      const employees = response.data.employees;

      const items = contracts.map((contract, index) => {
        const employee = employees.find((e) => e.employeeId === contract.employeeId);
        const employeeName = employee ? `${employee.firstName} ${employee.lastName}` : "Unknown";
        const department = employee ? `${employee.department}` : "Unknown";

        return {
          key: index.toString(),
          label: `Contract for ${employeeName} (Employee ID: ${contract.employeeId}, Department: ${department})`,
          children: (
            <Table
              dataSource={[
                { key: "ndafile", name: "NDA File", file: contract.ndafile },
                { key: "ncafile", name: "NCA File", file: contract.ncafile },
                {
                  key: "employeeAgreement",
                  name: "Employee Agreement",
                  file: contract.employeeAgreement,
                },
                { key: "i_nine", name: "I-9 File", file: contract.i_nine },
                { key: "w_four", name: "W-4 File", file: contract.w_four },
                { key: "offerLetter", name: "Offer Letter", file: contract.offerLetter },
              ]}
              pagination={false}
              columns={[
                { title: "File Name", dataIndex: "name", key: "name" },
                {
                  title: "Download/Preview",
                  key: "download",
                  render: (record) => (
                    <Space size="middle">
                      <FileOpenOutlined
                        onClick={() => handlePdfPreview(`${apiUrl}/${record.file}`)}
                        style={{ cursor: "pointer" }}
                      />
                    </Space>
                  ),
                },
              ]}
            />
          ),
        };
      });

      setItems(items);
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  };

  const handlePdfPreview = (fileUrl) => {
    try {
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Document Manager" className="card-professional">
            <Collapse accordion>
              {items.map((item) => (
                <Panel header={item.label} key={item.key}>
                  {item.children}
                </Panel>
              ))}
            </Collapse>
          </Card>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default DocumentManager;
