import { Button, Card, Table } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "StyleFeatures/SoftButton";
import SoftBox from "StyleFeatures/SoftBox";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Footer from "Components/authentication/components/Footer";
import { useNavigate } from "react-router-dom";

function SalaryBaseJobData() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dataSource, setDataSource] = useState([]);
  const [selectedSalary, setSelectedSalary] = useState("All Salaries");

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/Job/Get-Job-Data`);
        setDataSource(response.data); // Assuming response.data is an array of job objects
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    };

    fetchJobData();
  }, [apiUrl]);

  // Extracting unique salaries from dataSource
  const salaries = [...new Set(dataSource.map((job) => job.salary))];

  const columns = [
    {
      title: "Job_ID",
      dataIndex: "jobId",
      key: "jobId",
    },
    {
      title: "Job Name",
      dataIndex: "forJobApply",
      key: "forJobApply",
    },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
    },
    {
      title: "Hiring_Date",
      dataIndex: "hiringDate",
      key: "hiringDate",
      render: (hiringDate) =>
        new Date(hiringDate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
    },
    {
      title: "Experience Level",
      dataIndex: "experienceLevel",
      key: "experienceLevel",
    },
  ];

  const handleDownloadReport = () => {
    const filteredData = dataSource.filter(
      (job) => selectedSalary === "All Salaries" || job.salary === selectedSalary
    );

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Salary Analysis Report", 14, 22);

    const lineY = 28;
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192];
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor);
    doc.line(14, lineY, 200, lineY);

    const marginTop = 3;
    const marginTopEOM = 2;
    doc.setFontSize(12);
    doc.text(`Selected Salary: ${selectedSalary}`, 14, 32 + marginTop);

    // Get the current date
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();

    doc.text(`Report Date: ${formattedDate}`, 14, 42 + marginTopEOM);
    doc.text("Exceptions: No Deletions", 14, 52);

    const headers = [
      ["Job ID", "Job Name", "Salary", "Manager", "Hiring Date", "Experience Level"],
    ];
    const data = filteredData.map((job) => {
      return [
        job.jobId,
        job.forJobApply,
        job.salary,
        job.manager,
        new Date(job.hiringDate).toLocaleDateString("en-US"),
        job.experienceLevel,
      ];
    });

    doc.autoTable({
      startY: 62,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [192, 192, 192],
        fontSize: 8,
      },
      styles: {
        fontSize: 8,
      },
    });

    doc.save(`salary_analysis_report_${selectedSalary}.pdf`);
  };

  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        className="card-professional"
        title="Job Salary Analysis"
        extra={
          <Button type="default" onClick={back}>
            Back
          </Button>
        }
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SoftBox color="text" style={{ marginRight: "20px" }}>
            <div style={{ display: "inline-block" }}>
              <Button type="primary" onClick={handleDownloadReport}>
                <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Job Salary
                Reports
              </Button>
            </div>
          </SoftBox>
          <div className="mb-3" style={{ display: "inline-block" }}>
            <select
              className="form-select form-select-sm"
              value={selectedSalary}
              onChange={(e) => setSelectedSalary(e.target.value)}
            >
              <option value="All Salaries">All Salaries</option>
              {salaries.map((salary) => (
                <option key={salary} value={salary}>
                  {salary}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <Table
            className="table-responsive"
            bordered
            dataSource={dataSource.filter(
              (job) => selectedSalary === "All Salaries" || job.salary === selectedSalary
            )}
            columns={columns}
            pagination={{ pageSize: 10 }}
          />
        </div>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default SalaryBaseJobData;
