import React, { useState, useEffect } from "react";
import axios from "axios";
import { FileOpenOutlined } from "@mui/icons-material";
import { Space } from "antd";
import { toast } from "react-toastify";

function Service() {
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [employeeInterview, setEmployeeInterview] = useState([]);
  const [employeeContract, setEmployeeContract] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState("");
  const [showNavbar, setShowNavbar] = useState(true);
  const [submittedEmployees, setSubmittedEmployees] = useState({});
  const [isTimeSubmitted, setIsTimeSubmitted] = useState(false);
  const [isContractSubmitted, setIsContractSubmitted] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [isAllFilesSelected, setIsAllFilesSelected] = useState(false);
  const requiredFileTypes = [
    "ndafile",
    "ncafile",
    "employeeAgreement",
    "i_nine",
    "w_four",
    "offerLetter",
  ];

  useEffect(() => {
    fetchEmployeesByEmail();
    fetchEmployeeData();
    fetchContractByEmail();
    fetchSelectedDateTime;
  }, []);

  const [selectedTime, setSelectedTime] = useState("");

  const [employeeData, setEmployeeData] = useState(null);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const userEmail = localStorage.getItem("userEmail");
        const response = await axios.get(
          `${apiUrl}/api/EmployeeJobApplication/GetEmployeeByEmail/${userEmail}`
        );
        setEmployeeData(response.data); // Store the API data in state
      } catch (error) {
        console.error("Error fetching employee data", error);
      }
    };

    fetchEmployeeData();
  }, []);

  useEffect(() => {
    if (employeeInterview.length > 0) {
      setSelectedTime(employeeInterview[0].firstDateTime);
    }
  }, [employeeInterview]);

  async function fetchEmployeeData() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeInterview/GetInterviewByEmployeeEmail/${userEmail}`
        );
        const responseData = response.data;
        setEmployeeInterview(responseData);
      } else {
        throw new Error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  }

  async function employeeAction(email, firstName, lastName, forJobApply, password) {
    try {
      const emailData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        forJobApply: forJobApply,
        password: password,
      };

      await axios.post(`${apiUrl}/api/EmployeeJobApplication/EmployeeAction`, emailData);
      toast.success("Your response send to the organization thanks");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  }

  async function contractAcceptance(
    email,
    employeeId,
    status,
    ndafile,
    ncafile,
    employeeAgreement,
    i_nine,
    w_four,
    offerLetter
  ) {
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("employeeId", employeeId);
      formData.append("status", status);
      formData.append("ndafile", ndafile);
      formData.append("ncafile", ncafile);
      formData.append("employeeAgreement", employeeAgreement);
      formData.append("i_nine", i_nine);
      formData.append("w_four", w_four);
      formData.append("offerLetter", offerLetter);
      await axios.post(`${apiUrl}/api/EmployeeInterview/Contract-Acceptance`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Your contract response sended");
      setIsContractSubmitted(true);
    } catch (error) {
      toast.error("Please select all files");
    }
  }

  const handleShortlistClick = async (employee) => {
    await employeeAction(
      employee.email,
      employee.firstName,
      employee.lastName,
      employee.forJobApply,
      employee.password
    );
    setSubmittedEmployees((prev) => ({ ...prev, [employee.id]: true }));
  };

  async function fetchEmployeesByEmail() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeJobApplication/GetEmployeeByEmail/${userEmail}`
        );
        const responseData = response.data;

        if (Array.isArray(responseData)) {
          setEmployeeDetails(responseData);
        } else {
          setEmployeeDetails([responseData]);
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchContractByEmail() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeInterview/GetContractByEmail/${userEmail}`
        );
        const responseData = response.data;
        const modifiedResponseData = {
          ...responseData,
          contractId: responseData.contractId,
        };
        // setEmployeeContract(responseData);
        setEmployeeContract(modifiedResponseData);
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  }
  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const options = {
      month: "long",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
    };
    return date.toLocaleString("en-US", { ...options, hour12: true });
  }

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(null);

  // Function to handle when the user selects a time
  const handleTimeSelect = (employeeId, event) => {
    setSelectedEmployeeId(employeeId);
    setSelectedDateTime(event.target.value);
  };

  const handleSelectedTime = async () => {
    try {
      if (!selectedDateTime) {
        toast.error("please select time.");
        return;
      }

      // Make API call to send the selected time to the backend
      await axios.post(`${apiUrl}/api/EmployeeInterview/selected-schedule`, {
        employeeId: selectedEmployeeId,
        selectedDateTime: selectedDateTime,
      });
      toast.success("Your Interview Schedule is send to the Organization. Thanks!");
      setIsTimeSubmitted(true);
      localStorage.setItem("istimeSubmitted", true);
    } catch (error) {
      console.error("Error handling interview:", error);
    }
  };

  async function fetchSelectedDateTime() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeInterview/GetSelectedDateByEmployeeId/${userEmail}`
        );
        const responseData = response.data;
      } else {
        throw new Error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  }
  const handlePdfPreview = async (fileUrl) => {
    try {
      // Get the file extension
      const extension = fileUrl.split(".").pop().toLowerCase();

      if (extension === "pdf") {
        // If PDF, set the selected PDF state and open modal for preview
        setSelectedPdf(fileUrl);
        setShowNavbar(false);
        const modal = new bootstrap.Modal(document.getElementById("staticBackdrop"));
        modal.show();
      } else {
        // If not PDF, download the file
        window.open(fileUrl, "_blank");
      }
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  const handleContractChange = (e, fileType) => {
    const file = e.target.files[0];
    const allowedExtensions = [".pdf", ".PDF", ".docx", ".DOCX", ".doc", ".DOC"];

    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (!allowedExtensions.includes("." + fileExtension)) {
        alert("Unsupported file type. Only PDF and DOCX files are allowed.");
        return;
      }

      setEmployeeContract((prev) => {
        const updatedContract = {
          ...prev,
          [fileType]: file,
        };

        // Check if all required files are selected
        const allFilesSelected = requiredFileTypes.some((type) => updatedContract[type] != null);

        console.log("Updated Contract:", updatedContract);
        console.log("All Files Selected:", allFilesSelected);

        // Update state and ensure the component re-renders
        setIsAllFilesSelected(allFilesSelected);
        return updatedContract;
      });
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Download/Preview",
      key: "download",
      render: (record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(`${apiUrl}/${record.file}`)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
    {
      title: "Upload",
      key: "upload",
      render: (record) => (
        <Space size="middle">
          <input
            type="file"
            onChange={(e) => handleContractChange(e, record.key)}
            accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
          />
        </Space>
      ),
    },
  ];
  return {
    employeeContract,
    employeeDetails,
    columns,
    selectedTime,
    employeeData,
    isAllFilesSelected,
    handleSelectedTime,
    handleTimeSelect,
    formatDateTime,
    handleShortlistClick,
    contractAcceptance,
    selectedPdf,
    showNavbar,
    submittedEmployees,
    isContractSubmitted,
    isTimeSubmitted,
    employeeInterview,
    setSelectedTime,
    setShowNavbar,
  };
}
export default Service;
