import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import { useLocation } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Table } from "antd";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import SoftButton from "StyleFeatures/SoftButton";

// Initialize highcharts 3D module
highcharts3d(Highcharts);

function SpecRecruitment() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const [jobPostings, setJobPostings] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalJobPostings, setOriginalJobPostings] = useState([]);
  const [showViewAllButton, setShowViewAllButton] = useState(false);
  const [showViewAllButtonFromChart, setShowViewAllButtonFromChart] = useState(false);

  function formatDateTime(dateTimeString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Date(dateTimeString).toLocaleString("en-US", options);
  }

  const colors = ["green", "#dbb727", "#faae20", "#8e44ad", "#e74c3c"];

  const columns = [
    {
      title: "Position Name",
      dataIndex: "postingJobTitle",
      key: "postingJobTitle",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
    },
    {
      title: "Status",
      dataIndex: "jobStatus",
      key: "jobStatus",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/HiringProcess/Get-All-posting-Detail`);
        const formattedPostings = response.data.postingDetail.map((posting) => ({
          ...posting,
          createdOn: formatDateTime(posting.createdOn),
        }));

        // Set both originalJobPostings and jobPostings states with fetched data
        setOriginalJobPostings(formattedPostings);
        setJobPostings(formattedPostings);

        const statusCounts = {
          Open: 0,
          Draft: 0,
          "On Hold": 0,
          Filled: 0,
          Canceled: 0,
        };

        formattedPostings.forEach((posting) => {
          if (statusCounts[posting.jobStatus] !== undefined) {
            statusCounts[posting.jobStatus] += 1;
          }
        });

        setChartData([
          { name: "Open", y: statusCounts.Open },
          { name: "Draft", y: statusCounts.Draft },
          { name: "On Hold", y: statusCounts["On Hold"] },
          { name: "Filled", y: statusCounts.Filled },
          { name: "Canceled", y: statusCounts.Canceled },
        ]);

        // Initially hide the "View All" button
        setShowViewAllButton(false);
      } catch (error) {
        console.error("There was an error fetching the job postings!", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiUrl]);

  // const handleChartClick = (event) => {
  //   const { point } = event;
  //   let jobStatus = point.name;

  //   // Adjust the jobStatus if necessary
  //   if (jobStatus === "On Hold") {
  //     jobStatus = "On-Hold";
  //   }

  //   let filteredPostings = [];

  //   if (jobStatus === "View All") {
  //     filteredPostings = originalJobPostings;
  //     setShowViewAllButtonFromChart(false); // Hide the "View All" button if "View All" is clicked from the chart
  //   } else {
  //     filteredPostings = originalJobPostings.filter(
  //       (posting) =>
  //         posting.jobStatus.toLowerCase() === jobStatus.toLowerCase() ||
  //         posting.jobStatus.toLowerCase() === jobStatus.toLowerCase().replace("-", " ")
  //     );
  //     setShowViewAllButtonFromChart(true); // Show the "View All" button if any other job status is clicked from the chart
  //   }

  //   setJobPostings(filteredPostings);
  // };

  // const handleViewAllClick = () => {
  //   setJobPostings(originalJobPostings);
  //   setShowViewAllButton(false);
  //   setShowViewAllButtonFromChart(false);
  // };

  // const chartOptions = {
  //   chart: {
  //     type: "pie",
  //     options3d: {
  //       enabled: true,
  //       alpha: 45,
  //       beta: 0,
  //     },
  //   },
  //   title: {
  //     text: "",
  //   },
  //   plotOptions: {
  //     pie: {
  //       depth: 45,
  //       dataLabels: {
  //         enabled: true,
  //         format: "{point.name}",
  //       },
  //       point: {
  //         events: {
  //           click: handleChartClick,
  //         },
  //       },
  //     },
  //   },
  //   series: [
  //     {
  //       name: "Count",
  //       data: chartData,
  //       colors: colors,
  //     },
  //   ],
  // };

  return (
    <DashboardLayout key={location.pathname}>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Posted Job List" className="card-professional">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "400px" }}
              >
                <Oval height={80} width={80} color="blue" />
              </div>
            ) : (
              <>
                {/* <div className="row">
                  <div style={{ width: "950px", height: "400px", textAlign: "center" }}>
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                  </div>
                  {(showViewAllButton || showViewAllButtonFromChart) && (
                    <div className="col-md-12">
                      <SoftButton
                        variant="outlined"
                        style={{ float: "inline-end", marginBottom: "10px" }}
                        color="info"
                        size="small"
                        onClick={handleViewAllClick}
                      >
                        View All
                      </SoftButton>
                    </div>
                  )}
                </div> */}
                <div className="row">
                  <Table
                    className="table-responsive"
                    dataSource={jobPostings}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                    rowKey="postingJobId"
                  />
                </div>
              </>
            )}
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default SpecRecruitment;
