import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { message } from "antd";

function jobService(onNext) {
  const { empId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { personal } = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployeeJobData(empId);
  }, [empId]);

  async function fetchEmployeeJobData(empId) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/PersonalInformation/GetEmployeesById/${empId}`
      );
      const responseData = response.data;
      if (responseData.employeeJobApplications && responseData.employeeJobApplications.length > 0) {
        const firstEmployee = responseData.employeeJobApplications[0];
        const updatedFormData = { ...jobFormData };
        updatedFormData.forJobApply = firstEmployee.forJobApply;
        updatedFormData.team = firstEmployee.hiring.department;
        setJobFormData(updatedFormData);
      } else {
        console.error("No employee data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const [jobFormData, setJobFormData] = useState({
    forJobApply: "",
    hiringDate: "",
    team: "",
    manager: "",
    managerEmail: "",
    probationStartDate: "",
    probationEndDate: "",
    salary: "",
  });

  const [jobFormDataError, setJobFormDataError] = useState({
    jobTitleError: "",
    hiringDateError: "",
    teamError: "",
    managerError: "",
    probationStartDateError: "",
    probationEndDateError: "",
    salaryError: "",
  });

  const handleJobChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    if (name === "hiringDate" || name === "probationStartDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate >= currentDate) {
        setJobFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date before today.",
        }));
        return;
      }
    }
    if (name === "probationEndDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate <= currentDate) {
        setJobFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date after today.",
        }));
        return;
      }
    }

    setJobFormDataError({ ...jobFormDataError, [`${name}Error`]: "" });
    setJobFormData({ ...jobFormData, [name]: value });
  };

  function validateJobValidation(jobFormData, setJobFormDataError) {
    let hasError = false;

    Object.keys(jobFormData).forEach((fieldName) => {
      if (
        (typeof jobFormData[fieldName] === "string" &&
          (!jobFormData[fieldName] || !jobFormData[fieldName].trim())) || // For string fields
        (typeof jobFormData[fieldName] === "number" &&
          (isNaN(jobFormData[fieldName]) || jobFormData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setJobFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      }
    });

    if (hasError) {
      return false;
    }

    const allFieldsFilled = Object.values(jobFormData).every((value) => {
      return (
        (typeof value === "string" && value.trim() !== "") ||
        (typeof value === "number" && !isNaN(value))
      );
    });

    if (!allFieldsFilled) {
      setJobFormDataError((prevState) => ({
        ...prevState,
        commonError: "Please fill in all fields before saving.",
      }));
      return false;
    }

    return true;
  }

  const handleNext = async (e) => {
    e.preventDefault();

    // Validate the form data
    const isValid = validateJobValidation(jobFormData, setJobFormDataError);

    if (isValid) {
      const formData = new FormData();

      // Append the `personal` object properties to formData
      Object.entries(personal).forEach(([key, value]) => {
        formData.append(key, value); // Append `personal` data
      });

      // Append job properties to formData
      Object.entries(jobFormData).forEach(([key, value]) => {
        formData.append(`JobRequestDto.${key}`, value); // Append `jobFormData` data
      });

      try {
        setIsLoading(true); // Start the loading spinner

        // Make the API request to submit the formData
        await axios.post(`${apiUrl}/api/PersonalInformation/Addpersonal-info`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // Success message
        message.success(
          "Data has been added successfully, and email and password have been sent to the employee."
        );
        navigate("/employees");
      } catch (error) {
        // Error handling
        console.error("Error adding personal information:", error);

        // Check if the error is a bad request and display the specific backend error message
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data || "Failed to add personal information", {
            autoClose: 5000,
            style: { background: "lightcoral", color: "white" },
          });
        } else {
          // Handle other errors
          toast.error("An unexpected error occurred. Please try again.", {
            autoClose: 5000,
            style: { background: "lightcoral", color: "white" },
          });
        }
      } finally {
        setIsLoading(false); // Always stop the loading spinner
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await handleNext();
      if (success) {
        toast.success(
          "Data has Been Added Successfully and also email and password send to the employee Successfully",
          {
            autoClose: 5000,
            style: { background: "lightgreen", color: "white" },
          }
        );
      }
    } catch (error) {
      toast.error(error.message || "Added failed", {
        autoClose: 5000,
        style: { background: "lightcoral", color: "white" },
      });
    }
  };

  return {
    onSubmit,
    isLoading,
    jobFormData,
    setJobFormData,
    jobFormDataError,
    handleNext,
    handleJobChange,
  };
}

export default jobService;
