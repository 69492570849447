import React from "react";
import "./ViewCandidate.scss";
import {
  CalculateOutlined,
  CreditCard,
  FileOpenOutlined,
  PublishOutlined,
  StarOutlineSharp,
  ThumbDownOffAltOutlined,
} from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Empty, Modal, Radio, Spin, Tooltip } from "antd";
import { Table } from "antd";
import ViewCandidateService from "./ViewCandidateService";

function CandidateList() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const {
    renderButton,
    qualified,
    interview,
    pending,
    renderStatus,
    handleShortListClick,
    handleDelete,
    handlePdfPreview,
    handlecoverLetterPdfPreview,
    handleOnboarding,
    columns,
    openContract,
    employeeContract,
    contractPdf,
    handleContractCancel,
    employeeEmail,
    empId,
    handleJobOfferCancel,
    handleJobOfferClick,
    handleContractChange,
    jobOfferOpen,
    handleOk,
    handleCancel,
    employeeId,
    open,
    setOpen,
    email,
    firstDateTime,
    secondDateTime,
    thirdDateTime,
    setFirstDateTime,
    setSecondDateTime,
    setThirdDateTime,
    feedbackData,
    setFeedbackData,
    handleSubmitFeedback,
    setFeedbacks,
    openfeedback,
    setOpenfeedback,
    feedbackDetail,
    jobDetails,
    employeeDetails,
    isOverall,
    employeeCount,
    selectedPdf,
    selectedCoverLetter,
    emailSent,
    loading,
    loadingEmployeeId,
    formatDateTime,
    postingJobId,
    handleButtonClick,
  } = ViewCandidateService();

  return (
    <>
      <div className="row">
        <ToastContainer />
        <div className="col-md-4">
          <div className="card cardSet" style={{ height: "575px" }}>
            <div className="card-body" style={{ overflowY: "auto" }}>
              {" "}
              <div className="d-flex align-items-center">
                <span className="ml-2" style={{ fontSize: "medium", cursor: "pointer" }}>
                  CandidateList
                </span>
              </div>
              <hr style={{ marginTop: "5px" }}></hr>
              <div>
                {jobDetails && (
                  <>
                    <h3 style={{ color: "green", fontFamily: "system-ui" }}>
                      {jobDetails.postingInfo.postingJobTitle}
                    </h3>
                    <p className="textspan">
                      <CalculateOutlined /> &nbsp;&nbsp;
                      <span>{jobDetails.postingInfo.postingJobTitle}</span>
                    </p>
                    <p className="textp">
                      <CreditCard />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span>{jobDetails.postingInfo.compensation} USD/Month</span>
                    </p>
                    <p className="textp">
                      <PublishOutlined />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span>{formatDateTime(jobDetails.postingInfo.createdOn)}</span>
                    </p>
                    <p className="textp">
                      <span>
                        <StarOutlineSharp />
                        &nbsp;&nbsp;&nbsp;&nbsp;Status:{" "}
                        <span style={{ color: "green" }}>{jobDetails.postingInfo.jobStatus}</span>
                      </span>
                    </p>
                    {/* <p className="textp">
                      <span>
                        <CalendarMonthOutlined />
                        &nbsp;&nbsp;&nbsp;&nbsp;Application till:{" "}
                        <span style={{ color: "red" }}>05/02/2024</span>
                      </span>
                    </p> */}
                  </>
                )}
              </div>
              <div className="col-md-10">
                <h5>Candidate List</h5>
                <button
                  type="button"
                  onClick={() => handleButtonClick(postingJobId)}
                  className="btn-custom"
                >
                  Over All &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </button>
                <div className="number-box">
                  <span className="badge number-box-span">{employeeCount}</span>
                </div>
                <br />
                <button
                  type="button"
                  onClick={() => handleShortListClick(postingJobId, 3)}
                  className="btn-custom"
                >
                  Short List &nbsp;&nbsp;&nbsp;
                </button>
                <div className="number-box">
                  <span className="badge number-box-span">{pending}</span>
                </div>
                <br />
                <button
                  type="button"
                  onClick={() => handleShortListClick(postingJobId, 4)}
                  className="btn-custom"
                >
                  Interview &nbsp;&nbsp;&nbsp;&nbsp;
                </button>
                <div className="number-box">
                  <span className="badge number-box-span">{interview}</span>
                </div>
                <br />
                <button
                  type="button"
                  onClick={() => handleShortListClick(postingJobId, 8)}
                  className="btn-custom"
                >
                  Qualified &nbsp;&nbsp;&nbsp;&nbsp;
                </button>
                <div className="number-box">
                  <span className="badge number-box-span">{qualified}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div className="card cardSet-i" style={{ height: "575px" }}>
            <div className="d-flex align-items-center">
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="ml-2" style={{ fontSize: "medium", marginTop: "15px" }}></span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-end mb-3 right-align-container">
              <span className="spec-hr">Spec HR</span>&nbsp;&nbsp;
              <h6> Posting Process</h6>
            </div>

            <div className="scrollable-container">
              <div className="container">
                {loading ? (
                  <div
                    className="card"
                    style={{
                      height: "450px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spin size="large" />
                  </div>
                ) : (
                  <div>
                    {!employeeDetails ? (
                      <div
                        className="card"
                        style={{
                          height: "450px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Empty description="No Candidate available" />
                      </div>
                    ) : (
                      <div>
                        {employeeDetails
                          .filter((info) => !info.SoftDeleted)
                          .map((info) => (
                            <div className="card" key={info.key}>
                              <div className="card-body">
                                <p className="cardsecbodylist">
                                  {info.status === 2
                                    ? "Set Interview Schedule"
                                    : info.status === 3
                                    ? "Shortlist Candidate"
                                    : info.status === 4
                                    ? "Interview Process"
                                    : info.status === 5
                                    ? "Job Offer Process"
                                    : info.status === 6
                                    ? "Contract Sended"
                                    : info.status === 7
                                    ? "Candidate Contract Response"
                                    : info.status === 8
                                    ? "Qualified Candidate"
                                    : "Candidate Applied"}
                                </p>
                                <div
                                  className="col-md-10"
                                  style={{
                                    fontSize: "medium",
                                    marginLeft: "28px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <div className="col-md-8">
                                    <b>Name:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.firstName} {info.lastName}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Email:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.email}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Phone Number:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.mobileno}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Address:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.streetAddress}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>City:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.city}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>State:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.state}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>ZipCode:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.postalCode}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Applied On:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.appliedOn}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Interview time:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.dateAvailable}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Status:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {renderStatus(info.status)}
                                    </span>
                                  </div>
                                  {feedbackDetail &&
                                    feedbackDetail.length > 0 &&
                                    feedbackDetail
                                      .filter((feedbackItem) => feedbackItem.email === info.email)
                                      .map((filteredFeedbackItem, index) => (
                                        <div key={index}>
                                          <b>Feedback Mark Average:</b>&nbsp;
                                          <span className="card-subtitle mb-2 text-muted">
                                            {filteredFeedbackItem.marksAverage}
                                          </span>
                                        </div>
                                      ))}
                                </div>
                                <br></br>
                                <div className="col-md-12" style={{ marginTop: "-20px" }}>
                                  <FileOpenOutlined
                                    onClick={() => handlePdfPreview(`${apiUrl}/${info.resume}`)}
                                    style={{ marginLeft: "25px", cursor: "pointer" }}
                                    title="Resume"
                                  />
                                  <FileOpenOutlined
                                    onClick={() =>
                                      handlecoverLetterPdfPreview(`${apiUrl}/${info.coverLetter}`)
                                    }
                                    style={{ marginLeft: "15px", cursor: "pointer" }}
                                    title="Cover Letter"
                                  />
                                </div>
                                {/* Render button for all candidates */}
                                <div className="col-md-12">
                                  <>
                                    {renderButton(info)}
                                    {info.status !== 5 &&
                                      info.status !== 6 &&
                                      info.status !== 7 &&
                                      info.status !== 8 && (
                                        <button
                                          style={{ float: "inline-end", marginRight: "10px" }}
                                          type="button"
                                          className="btn btn-outline-danger"
                                          onClick={() =>
                                            handleDelete(info.employeeId, {
                                              firstName: info.firstName,
                                              lastName: info.lastName,
                                              email: info.email,
                                              forJobApply: info.forJobApply,
                                              Password: info.password,
                                            })
                                          }
                                          disabled={loadingEmployeeId === info.employeeId} // Disable the button when it's loading
                                        >
                                          <ThumbDownOffAltOutlined />
                                          &nbsp;
                                          <span style={{ fontSize: "medium" }}>
                                            {loadingEmployeeId === info.employeeId
                                              ? "Please Wait..."
                                              : "Not Suitable"}
                                          </span>
                                        </button>
                                      )}
                                  </>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <embed src={selectedPdf} type="application/pdf" width="100%" height="450px" />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="coverLetter"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="coverLetterLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <embed
                    src={selectedCoverLetter}
                    type="application/pdf"
                    width="100%"
                    height="450px"
                  />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* -----------Interview Date Option Modal------------ */}
          <Modal
            open={open}
            title="Interview Schedule"
            onOk={() => handleOk(employeeId, email, firstDateTime, secondDateTime, thirdDateTime)}
            onCancel={handleCancel}
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )}
          >
            <hr></hr>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="firstDateTime"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  First Date time *
                </label>
                <input
                  id="firstDateTime"
                  type="datetime-local"
                  value={firstDateTime}
                  onChange={(e) => setFirstDateTime(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="secondDateTime"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Second Date time *
                </label>
                <input
                  id="secondDateTime"
                  type="datetime-local"
                  value={secondDateTime}
                  onChange={(e) => setSecondDateTime(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="thirdDateTime"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Third Date time *
                </label>
                <input
                  id="thirdDateTime"
                  type="datetime-local"
                  value={thirdDateTime}
                  onChange={(e) => setThirdDateTime(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
          </Modal>
          {/* {--------------Feedback Modal---------------} */}

          <Modal
            open={openfeedback}
            title="Interview Feedback"
            onOk={handleSubmitFeedback}
            onCancel={() => setOpenfeedback(false)}
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn onClick={handleSubmitFeedback} />
              </>
            )}
            style={{ width: "auto" }} // Responsive width for the modal
          >
            <hr />
            {[
              { label: "Interview Rating", stateKey: "Rating" },
              { label: "Communication Skill", stateKey: "CommunicationSkill" },
              { label: "Technical Aptitude", stateKey: "TechnicalAptitude" },
              { label: "Attitude and Motivation", stateKey: "AttitudeAndMotivation" },
              { label: "Critical Thinking", stateKey: "CriticalThinking" },
              { label: "Professionalism", stateKey: "Professionalism" },
              { label: "Organizational Fit", stateKey: "OrganizationalFit" },
              { label: "Suitable for Post", stateKey: "SuitableForPost" },
            ].map((item) => (
              <div className="questionWithRadio" key={item.label}>
                {/* Show tooltip only on smaller screens */}
                <Tooltip title={item.label}>
                  <p className="responsive-label">{item.label}</p>
                </Tooltip>
                <Radio.Group
                  value={feedbackData[item.stateKey]}
                  onChange={(e) =>
                    setFeedbackData({ ...feedbackData, [item.stateKey]: e.target.value })
                  }
                >
                  {[1, 2, 3, 4, 5].map((value) => (
                    <Radio key={value} value={value}>
                      {value}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            ))}
          </Modal>

          {/* -----------Interview Job Offer Modal------------ */}
          <Modal
            open={jobOfferOpen}
            title="Job Offer"
            onOk={handleJobOfferClick} // Just pass the function without calling it
            onCancel={handleJobOfferCancel}
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn /> {/* Just pass the function without calling it */}
              </>
            )}
          >
            <hr></hr>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleJobOfferClick();
              }}
              encType="multipart/formData"
            >
              {" "}
              {/* Prevent default form submission */}
              <div className="col-md-8 mb-3">
                <div className="form-group">
                  <label
                    htmlFor="contractfile"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Choose NDA File *
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                    onChange={(e) => {
                      handleContractChange(e, "ndafile");
                    }}
                  />
                </div>
              </div>
              <div className="col-md-8 mb-3">
                <div className="form-group">
                  <label
                    htmlFor="contractfile"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Choose NCA File *
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                    onChange={(e) => {
                      handleContractChange(e, "ncafile");
                    }}
                  />
                </div>
              </div>
              <div className="col-md-8 mb-3">
                <div className="form-group">
                  <label
                    htmlFor="contractfile"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Choose Employee Agreement File *
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                    onChange={(e) => {
                      handleContractChange(e, "employeeAgreement");
                    }}
                  />
                </div>
              </div>
              <div className="col-md-8 mb-3">
                <div className="form-group">
                  <label
                    htmlFor="contractfile"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Choose Offer Letter *
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                    onChange={(e) => {
                      handleContractChange(e, "offerLetter");
                    }}
                  />
                </div>
              </div>
            </form>
          </Modal>
          {/* -----------Contract Modal------------ */}

          <Modal
            open={openContract}
            title="Employee Contract Documents"
            // onOk={handleJobOfferClick}
            onCancel={handleContractCancel}
            footer={(_, { CancelBtn }) => (
              <>
                <CancelBtn />
                <Button onClick={() => handleOnboarding(empId, employeeEmail)}>Onboarding</Button>
              </>
            )}
          >
            <hr></hr>
            <div className="row">
              <div className="row mb-3">
                {employeeContract ? (
                  <Table
                    dataSource={[
                      employeeContract.ndafile
                        ? { key: "ndafile", name: "NDA File", file: employeeContract.ndafile }
                        : null,
                      employeeContract.ncafile
                        ? { key: "ncafile", name: "NCA File", file: employeeContract.ncafile }
                        : null,
                      employeeContract.employeeAgreement
                        ? {
                            key: "employeeAgreement",
                            name: "Employee Agreement",
                            file: employeeContract.employeeAgreement,
                          }
                        : null,
                      employeeContract.i_nine
                        ? { key: "i_nine", name: "I-9 File", file: employeeContract.i_nine }
                        : null,
                      employeeContract.w_four
                        ? { key: "w_four", name: "W-4 File", file: employeeContract.w_four }
                        : null,
                      employeeContract.offerLetter
                        ? {
                            key: "offerLetter",
                            name: "Offer Letter",
                            file: employeeContract.offerLetter,
                          }
                        : null,
                    ].filter((item) => item !== null)} // Filter out null values
                    pagination={false}
                    columns={columns}
                  />
                ) : (
                  <Empty description="Sorry, you don`t have any documents." />
                )}
              </div>
            </div>
          </Modal>

          <div
            className="modal fade"
            id="staticBackdropc"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <embed src={contractPdf} type="application/pdf" width="100%" height="450px" />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CandidateList;
