import { Table, Select, Card, Button } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "StyleFeatures/SoftBox";
import SoftButton from "StyleFeatures/SoftButton";
import Footer from "Components/authentication/components/Footer";
import Service from "./Service";
import { useNavigate } from "react-router-dom";

function SkillsReport() {
  const {
    filteredData,
    selectedSkills,
    skills,
    columns,
    handleSkillsChange,
    handleDownloadReport,
  } = Service();

  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        className="card-professional"
        title="Skills Report"
        extra={
          <Button type="default" onClick={back}>
            Back
          </Button>
        }
      >
        <div className="mb-3 d-flex justify-content-between">
          <div className="col-md-5 mb-3">
            <div className="form-group">
              <Select
                mode="tags"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select skills"
                options={skills.map((skill) => ({ value: skill, label: skill }))}
                onChange={handleSkillsChange}
                value={selectedSkills}
              />
            </div>
          </div>
          <SoftBox color="text" style={{ marginRight: "20px" }}>
            <Button type="primary" onClick={handleDownloadReport}>
              <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Skills Wise
              Reports
            </Button>
          </SoftBox>
        </div>

        <div className="row mb-3">
          <Table
            className="table-responsive"
            bordered
            dataSource={filteredData}
            columns={columns}
            pagination={{ pageSize: 10 }}
            rowKey="employeeId" // Ensure each row has a unique key
          />
        </div>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default SkillsReport;
