import { Button, Card, Table } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "Components/authentication/components/Footer";
import Service from "./Service";

function TrainingDetail() {
  const {
    trainingDetail,
    enrolledCount,
    dataSourceenrolled,
    formatDateTime,
    columnsTrainingDetail,
    goBack,
  } = Service();
  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card
            className="card-professional"
            title="Training Details"
            extra={
              <Button type="primary" onClick={goBack}>
                Back to List
              </Button>
            }
          >
            <div className="row">
              <div className="col-md-5">
                <div
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <h6
                    className="h6-trainingdetail"
                    style={{ marginLeft: "15px", color: "cadetblue" }}
                  >
                    Training Detail
                  </h6>
                </div>
                {trainingDetail && (
                  <>
                    <div className="card cardSet">
                      <div className="card-body">
                        <div className="trainingDetail-card mb-2">
                          <h6 className="h6-trainingdetail">Name </h6>
                          <span className="h6-trainingdetail">
                            {trainingDetail.trainingInfo.trainingName}
                          </span>
                        </div>
                        <div className="trainingDetail-card mb-2">
                          <h6 className="h6-trainingdetail">Provider</h6>
                          <span className="h6-trainingdetail">
                            {trainingDetail.trainingInfo.provider}
                          </span>
                        </div>
                        <div className="trainingDetail-card mb-2">
                          <h6 className="h6-trainingdetail">Mentor</h6>
                          <span className="h6-trainingdetail">
                            {trainingDetail.trainingInfo.mentor}
                          </span>
                        </div>
                        <div className="trainingDetail-card mb-2">
                          <h6 className="h6-trainingdetail">Budget</h6>
                          <span className="h6-trainingdetail">
                            {trainingDetail.trainingInfo.allocated_budget}
                          </span>
                        </div>
                        <div className="trainingDetail-card mb-2">
                          <h6 className="h6-trainingdetail">Enrollments</h6>
                          <span className="h6-trainingdetail">{enrolledCount}</span>{" "}
                          {/* Displaying the enrolled count */}
                        </div>
                        <div className="trainingDetail-card mb-2">
                          <h6 className="h6-trainingdetail">Start Date</h6>
                          <span className="h6-trainingdetail">
                            {formatDateTime(trainingDetail.trainingInfo.startDate)}
                          </span>
                        </div>
                        <div className="trainingDetail-card mb-2">
                          <h6 className="h6-trainingdetail">End Date</h6>
                          <span className="h6-trainingdetail">
                            {formatDateTime(trainingDetail.trainingInfo.endDate)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-7">
                <div
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <h6
                    className="h6-trainingdetail"
                    style={{ marginLeft: "15px", color: "cadetblue" }}
                  >
                    Enrolled Employee
                  </h6>
                </div>
                <div className="card" style={{ marginTop: "10px", marginLeft: "15px" }}>
                  <div className="card-body">
                    <Table
                      className="table-responsive"
                      bordered
                      dataSource={dataSourceenrolled}
                      columns={columnsTrainingDetail}
                      pagination={{ pageSize: 10 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default TrainingDetail;
