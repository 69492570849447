import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import routes from "routes";
import employeeRoutes from "employeeRoutes";
import EmployeeSidenav from "examples/Sidenav";

import { useSoftUIController } from "context";

//HR Manager Routes
import brand from "assets/images/datagonomix.jpg";
import HRRoutes from "Components/HRRoutesLink";
import EmployeeRoutes from "EmployeeDashboard/EmployeeRoutesLink";
import ProtectedHRRoute from "Components/authentication/ProtectedHRFile/ProtectedHRFile";
import ProtectedRoute from "EmployeeDashboard/authentication/ProtectedRoute/ProtectedRoute";

//Employee Routes
// import EmployeeJob from "EmployeeDashboard/EmployeeJob/EmployeeJob";

export default function App() {
  const [controller] = useSoftUIController();
  const { layout, sidenavColor } = controller;
  const location = useLocation();

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        let element;
        if (
          route.route === "/dashboard" ||
          route.route === "/employees" ||
          route.route === "/hiring" ||
          route.route === "/report" ||
          route.route === "/performance" ||
          route.route === "/training" ||
          route.route === "/assets" ||
          route.route === "/complaints" ||
          route.route === "/compliance"
        ) {
          element = <ProtectedHRRoute element={route.component} />;
        } else if (
          route.route.startsWith("/employeedashboard") ||
          route.route === "/employeedashboard/personal" ||
          route.route === "/employeedashboard/jobemployee" ||
          route.route === "/employeedashboard/attendance" ||
          route.route === "/employeedashboard/benefits" ||
          route.route.startsWith("/employeedashboard/training") ||
          route.route === "/employeedashboard/documents" ||
          route.route === "/employeedashboard/emergency" ||
          route.route === "/employeedashboard/assets" ||
          route.route === "/employeedashboard/onboarding" ||
          route.route === "/employeedashboard/complaints"
        ) {
          element = <ProtectedRoute element={route.component} />;
        } else {
          element = route.component;
        }

        return <Route exact path={route.route} element={element} key={route.key} />;
      }

      return null;
    });

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }
  
  //     if (route.route) {
  //       const isEmployeeDashboardRoute = route.route.startsWith("/employeedashboard");
  
  //       const element = isEmployeeDashboardRoute
  //         ? <ProtectedRoute element={route.component} />
  //         : <ProtectedHRRoute element={route.component} />;
  
  //       return <Route exact path={route.route} element={element} key={route.key} />;
  //     }
  
  //     return null;
  //   });
  

  const showSidebar = ![
    "/viewcareer",
    "/emplogin",
    /^\/Employee-Onboarding\/[^/]+\/[^/]+$/,
    /^\/hiringOnboarding\/[^/]+\/[^/]+$/,
    /^\/viewcandidate\/[^/]+$/,
    /^\/hiringOnboarding\/[^/]+$/,
    /^\/jobOpenings\/[^/]+$/,
    /^\/announcement-detail\/[^/]+$/,
    "/shortlistEmployeeform",
  ].some((pattern) => {
    if (typeof pattern === "string") {
      return location.pathname === pattern;
    } else if (pattern instanceof RegExp) {
      return pattern.test(location.pathname);
    }
  });

  const isEmployeeDashboard = location.pathname.startsWith("/employeedashboard");

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && showSidebar && (
        <>
          {isEmployeeDashboard ? (
            <EmployeeSidenav
              color={sidenavColor}
              brand={brand}
              brandName="Employee Dashboard"
              routes={employeeRoutes}
            />
          ) : (
            <Sidenav color={sidenavColor} brand={brand} brandName="Spec HR" routes={routes} />
          )}
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        {/* HR Manager Routes Start */}
        {HRRoutes()}
        {/* HR Manager Routes End */}
        {getRoutes(employeeRoutes)}
        {/* Employee Routes Start*/}
        {EmployeeRoutes()}
        {/* Employee Routes End */}
      </Routes>
    </ThemeProvider>
  );
}
