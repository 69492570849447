import React, { useState } from "react";
import SoftBox from "StyleFeatures/SoftBox";
import SoftTypography from "StyleFeatures/SoftTypography";
import SoftInput from "StyleFeatures/SoftInput";
import SoftButton from "StyleFeatures/SoftButton";
import axios from "axios";
import CoverLayout from "Components/authentication/components/CoverLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import { useNavigate } from "react-router-dom";

function FinalSignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  debugger;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setEmailError(null);
    setPasswordError(null);

    // Validate input fields
    let valid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      valid = false;
    }
    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    }

    if (!valid) {
      return;
    }

    try {
      setIsLoading(true);
      // Make POST request to login endpoint
      const response = await axios.post(`${apiUrl}/api/PersonalInformation/Final-Login`, {
        email: email,
        password: password,
      });

      if (response && response.data && response.data.email) {
        const loginEmail = response.data.email;

        // Store user's email in local storage
        localStorage.setItem("loginEmail", loginEmail);
        // Handle successful login
        navigate("/employeedashboard");
      } else {
        // Handle unexpected response format
        console.error("Unexpected response format:", response);
        setError("Unexpected response format");
      }
    } catch (error) {
      // Handle login error
      console.error("Login failed:", error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data : error.message);
      toast.error(error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CoverLayout
        title="Employee Login"
        description="Enter your email and password to sign in"
        image={curved9}
      >
        <SoftBox component="form" role="form" onSubmit={handleSubmit}>
          <ToastContainer position="top-left" />
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Email
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(null);
              }}
              className={`form-control ${emailError ? "is-invalid" : ""}`}
            />
            {emailError && (
              <SoftTypography color="error" variant="caption">
                {emailError}
              </SoftTypography>
            )}
          </SoftBox>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Password
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError(null);
              }}
              className={`form-control ${passwordError ? "is-invalid" : ""}`}
            />
            {passwordError && (
              <SoftTypography color="error" variant="caption">
                {passwordError}
              </SoftTypography>
            )}
          </SoftBox>
          {error && (
            <SoftBox mb={2}>
              <SoftTypography color="error" variant="body2">
                {error}
              </SoftTypography>
            </SoftBox>
          )}
          <SoftBox mt={4} mb={1}>
            <SoftButton type="submit" variant="gradient" color="info" fullWidth>
              {isLoading ? "Please Wait..." : "Login"}
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </CoverLayout>
    </>
  );
}

export default FinalSignIn;
