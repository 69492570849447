import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import SoftButton from "StyleFeatures/SoftButton";
import SoftBox from "StyleFeatures/SoftBox";
import SoftTypography from "StyleFeatures/SoftTypography";
import Tables from "examples/Tables/Table";
import { useNavigate } from "react-router-dom";
import data from "../ExpenseManagment/data";
import React, { useRef } from "react";
import ExpenseSheet from "assets/DownloadExpenseSheet/ExpenseReportTEMPLATE.xls";

function PeopleTable() {
  const { columns, rows, departments, selectedDepartment, handleDepartmentChange } = data();
  const navigate = useNavigate();

  const handleAddExpense = () => {
    navigate("/employeedashboard/addEmployeeExpense");
  };
  const handleExpenseRequest = () => {
    navigate("/employeedashboard/employeeExpenseRequest");
  };

  const anchorRef = useRef(null);

  const handleDownloadExcelTemplate = () => {
    anchorRef.current.click();
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
              <SoftTypography variant="h6" gutterBottom>
                Expense Report
              </SoftTypography>
            </SoftBox>
            <>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <a
                  href={ExpenseSheet} // URL of the Excel file
                  download="ExpenseSheet.xls" // Filename for the downloaded file
                  style={{ display: "none" }} // Hide the anchor tag
                  ref={anchorRef} // Reference to the anchor tag
                ></a>
                <SoftBox color="text" mt={-6} px={2} style={{ marginRight: "-20px" }}>
                  <div style={{ display: "inline-block" }}>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={handleDownloadExcelTemplate}
                    >
                      <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Expense
                      Sheet
                    </SoftButton>
                  </div>
                </SoftBox>
                <SoftBox color="text" mt={-6} px={2}>
                  <div style={{ display: "inline-block" }}>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={handleAddExpense}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add Expense
                    </SoftButton>
                  </div>
                </SoftBox>
              </div>

              <br></br>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
                <SoftBox style={{ display: "flex", justifyContent: "space-between" }}></SoftBox>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SoftBox color="text" mt={4} style={{ marginRight: "10px" }}>
                    <div style={{ display: "inline-block" }}>
                      <SoftButton
                        variant="gradient"
                        size="small"
                        onClick={handleExpenseRequest}
                      >
                        <i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;Employee
                        Expense Request
                      </SoftButton>
                    </div>
                  </SoftBox>
                  <SoftBox color="text" px={2}>
                    <div style={{ display: "inline-block" }}>
                      <label style={{ fontSize: "15px" }}>Select Department</label>
                      <select
                        className="form-select form-select-sm"
                        value={selectedDepartment}
                        onChange={handleDepartmentChange}
                      >
                        <option value="All Employees">All Employees</option>
                        {departments.map((dept, index) => (
                          <option key={index} value={dept}>
                            {dept}
                          </option>
                        ))}
                      </select>
                    </div>
                  </SoftBox>
                </div>
              </SoftBox>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {rows.length === 0 ? (
                  <SoftTypography variant="h6" align="center" p={2}>
                    No Data Available
                  </SoftTypography>
                ) : (
                  <Tables
                    className="table-responsive"
                    bordered
                    rows={rows}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                  />
                )}
              </SoftBox>
            </>
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default PeopleTable;
