import React, { useState, useEffect, useRef } from "react";
import { Table, message, Space, Tooltip, Card, Button, Select } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import { FileOpenOutlined } from "@mui/icons-material";
import { EyeOutlined } from "@ant-design/icons";
import SoftButton from "StyleFeatures/SoftButton";
import SoftTypography from "StyleFeatures/SoftTypography";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import Footer from "Components/authentication/components/Footer";
import { useNavigate } from "react-router-dom";

function EmployeeExpenseRequest() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");
  const [departments, setDepartments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployeesByManager();
  }, []);

  async function fetchEmployeesByManager() {
    try {
      setLoading(true);
      const employeesResponse = await axios.get(
        `${apiUrl}/api/ExpenseManagement/Get-ExpenseManagement`
      );
      const employeesData = employeesResponse.data;

      if (Array.isArray(employeesData) && employeesData.length > 0) {
        setEmployees(employeesData);
        const uniqueDepartments = [
          ...new Set(employeesData.map((employee) => employee.department)),
        ];
        setDepartments(uniqueDepartments);
      } else {
        console.error("No employee data found for the manager");
        setEmployees([]);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }

  const handlePdfPreview = async (fileUrl) => {
    try {
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  // Function to handle eye icon click
  const handleEyeIconClick = (record) => {
    setSelectedEmployee(record);
  };

  // Function to handle Download All button click for Excel
  const handleDownloadAll = () => {
    const approvedEmployees = filteredEmployees
      .filter((employee) => employee.status === 1 || employee.status === 2 || employee.status === 3)
      .map(({ receipt, calculationSheet, softDeleted, deletedAt, ...rest }) => ({
        ...rest,
        status: getStatusLabel(rest.status),
      }));

    const ws = XLSX.utils.json_to_sheet(approvedEmployees);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Approved Expense Requests");

    XLSX.writeFile(wb, "Approved_Expense_Requests.xlsx");
  };

  const handleDownloadPdf = () => {
    const approvedEmployees = filteredEmployees.filter(
      (employee) => employee.status === 1 || employee.status === 2 || employee.status === 3
    );

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("End of Months Report", 14, 22);

    const lineY = 28; // Adjust the Y-coordinate as needed
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192]; // Gray color for the line
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor); // Set line color
    doc.line(14, lineY, 200, lineY); // Adjust the X-coordinate and length as needed

    const marginTop = 3;
    const marginTopEOM = 2;
    doc.setFontSize(12);
    doc.text("Employee: All - All employees selected", 14, 32 + marginTop); // Adjust the Y-coordinate as needed

    // Get the current date
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(); // Formats the date as MM/DD/YYYY

    doc.text(`End of Months: ${formattedDate}`, 14, 42 + marginTopEOM);
    doc.text("Exceptions: No Deletions", 14, 52);

    const headers = [
      [
        "Request Date",
        "Approved Date",
        "Department",
        "Employee ID",
        "Employee Name",
        "Requested to",
        "Charges",
        "Status",
      ],
    ];
    const data = approvedEmployees.map((emp) => [
      emp.date,
      emp.approveddate,
      emp.department,
      emp.personalId,
      emp.employeeName,
      emp.manager,
      emp.charges,
      getStatusLabel(emp.status),
    ]);

    // Calculate total charges
    const totalCharges = approvedEmployees.reduce((total, emp) => total + emp.charges, 0);

    // Add total charges row
    const totalRow = ["Total Charges", "", "", "", "", "", totalCharges, ""];
    data.push(totalRow);

    doc.autoTable({
      startY: 62,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [192, 192, 192],
        fontSize: 8,
      },
      styles: {
        fontSize: 8,
      },
    });

    doc.save("End_of_Day_Report.pdf");
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return "Inprocess";
      case 2:
        return "Approved";
      case 3:
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  const handleUpdateStatus = async (status) => {
    try {
      const requestData = {
        ExpenseManagementId: selectedEmployee.expenseManagementId,
        Status: status, // Pass the status dynamically
      };

      const response = await axios.post(
        `${apiUrl}/api/ExpenseManagement/Update-Expense-Status`,
        requestData
      );

      if (response.status === 200) {
        message.success("Expense status updated successfully");
        setSelectedEmployee(null); // Reset selectedEmployee after successful update
        fetchEmployeesByManager(); // Refresh employee list
      } else {
        message.error("Failed to update expense status");
      }
    } catch (error) {
      console.error("Error updating expense status:", error);
      message.error("Failed to update expense status");
    }
  };

  const filteredEmployees =
    selectedDepartment === "All Departments"
      ? employees.filter((employee) => employee.status !== 0)
      : employees.filter(
          (employee) => employee.status !== 0 && employee.department === selectedDepartment
        );

  const isApprovedDateAvailable = employees.some((employee) => employee.approveddate);

  const columns = [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      render: (_, record) => (
        <div>
          <div>
            {record.personalId} |{" "}
            <Tooltip title={record.employeeName}>
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                {record.employeeName.length > 13
                  ? `${record.employeeName.slice(0, 13)}...`
                  : record.employeeName}
              </span>
            </Tooltip>
          </div>
        </div>
      ),
    },

    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: 100,
            }}
          >
            {text.length > 5 ? `${text.slice(0, 5)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: 100,
            }}
          >
            {text.length > 5 ? `${text.slice(0, 5)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Charges",
      dataIndex: "charges",
      key: "charges",
    },
    {
      title: "Request_Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("MMMM D, YYYY"),
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      key: "receipt",
      render: (_, record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(`${apiUrl}/${record.receipt}`)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
    {
      title: "Expense",
      dataIndex: "calculationSheet",
      key: "calculationSheet",
      render: (_, record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(`${apiUrl}/${record.calculationSheet}`)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
    {
      title: "Actions",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Space size="middle">
          <SoftTypography variant="caption" color="text" fontWeight="medium">
            {status === 0
              ? "Pending"
              : status === 1
              ? "Inprocess"
              : status === 2
              ? "Approved"
              : "Rejected"}
          </SoftTypography>
          <EyeOutlined onClick={() => handleEyeIconClick(record)} style={{ cursor: "pointer" }} />
        </Space>
      ),
    },
  ];

  const Back = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        className="card-professional"
        title={selectedEmployee ? "Employee Detail" : "Employee Expense Request"}
        extra={
          <Button type="default" onClick={Back}>
            Back
          </Button>
        }
      >
        {selectedEmployee ? (
          <div>
            <p className="h6-trainingdetail">
              <strong>Expense ID:</strong> {selectedEmployee.expenseManagementId}
            </p>
            <p className="h6-trainingdetail">
              <strong>Name:</strong> {selectedEmployee.employeeName}
            </p>
            <p className="h6-trainingdetail">
              <strong>Email:</strong> {selectedEmployee.email}
            </p>
            <p className="h6-trainingdetail">
              <strong>Request To:</strong> {selectedEmployee.manager}
            </p>
            <p className="h6-trainingdetail">
              <strong>Request Date:</strong> {selectedEmployee.date}
            </p>
            <p className="h6-trainingdetail">
              <strong>Approved Date:</strong> &nbsp;
              {isApprovedDateAvailable
                ? moment(selectedEmployee.approveddate).format("MMMM D, YYYY h:mm A")
                : "Not Approved yet!"}
            </p>

            <p className="h6-trainingdetail">
              <strong>Description:</strong> {selectedEmployee.description}
            </p>
            <p className="h6-trainingdetail">
              <strong>Status:</strong>{" "}
              {selectedEmployee.status === 0
                ? "Pending"
                : selectedEmployee.status === 1
                ? "Approved"
                : "Rejected"}
            </p>
            <p className="h6-trainingdetail">
              <strong>Receipt:</strong>&nbsp;&nbsp;&nbsp;&nbsp;
              <Space size="middle">
                <FileOpenOutlined
                  onClick={() => handlePdfPreview(`${apiUrl}/${selectedEmployee.receipt}`)}
                  style={{ cursor: "pointer" }}
                />
              </Space>
            </p>
            <p className="h6-trainingdetail">
              <strong>Expense Sheet:</strong> &nbsp;&nbsp;&nbsp;&nbsp;
              <Space size="middle">
                <FileOpenOutlined
                  onClick={() => handlePdfPreview(`${apiUrl}/${selectedEmployee.calculationSheet}`)}
                  style={{ cursor: "pointer" }}
                />
              </Space>
            </p>
            <Space size="middle">
              <SoftButton
                onClick={() => setSelectedEmployee(null)}
                variant="outlined"
                color="primary"
              >
                Close
              </SoftButton>
              <SoftButton
                onClick={() => handleUpdateStatus("Rejected")}
                variant="outlined"
                color="error"
              >
                Reject
              </SoftButton>
              <SoftButton
                onClick={() => handleUpdateStatus("Approved")}
                variant="outlined"
                color="success"
              >
                Approved
              </SoftButton>
            </Space>
          </div>
        ) : (
          <>
            <Space size="middle" style={{ marginBottom: "20px" }}>
              <Button
                onClick={handleDownloadAll}
                type="default"
                style={{ borderColor: "#52c41a", color: "#52c41a" }}
              >
                Download Approved Request in Excel
              </Button>

              <Button
                onClick={handleDownloadPdf}
                type="default"
                style={{ borderColor: "#1890ff", color: "#1890ff" }}
              >
                Download Approved Request in PDF
              </Button>

              <Select
                value={selectedDepartment}
                onChange={(value) => setSelectedDepartment(value)}
                style={{ width: 200, display: "inline-block", marginLeft: "8px" }}
              >
                <Select.Option value="All Departments">All Departments</Select.Option>
                {departments.map((department) => (
                  <Select.Option key={department} value={department}>
                    {department}
                  </Select.Option>
                ))}
              </Select>
            </Space>
            <Table
              className="table-responsive"
              columns={columns}
              dataSource={filteredEmployees}
              rowKey="expenseManagementId"
              loading={loading}
              pagination={{ pageSize: 10 }}
            />
          </>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default EmployeeExpenseRequest;
