import React, { useState, useEffect } from "react";
import { Table, message, Space } from "antd";
import SoftBadge from "StyleFeatures/SoftBadge";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import axios from "axios";
import { FileOpenOutlined } from "@mui/icons-material";
import SoftButton from "StyleFeatures/SoftButton";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import SoftTypography from "StyleFeatures/SoftTypography";

function EmployeeExpenseRequest() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedEmployee, setSelectedEmployee] = useState(null); // State to manage selected employee detail

  const columns = [
    {
      title: "Expense ID",
      dataIndex: "expenseManagementId",
      key: "expenseManagementId",
    },
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
    },
    {
      title: "Request To",
      dataIndex: "manager",
      key: "manager",
    },
    {
      title: "Request Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Charges",
      dataIndex: "charges",
      key: "charges",
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      key: "receipt",
      render: (_, record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(`${apiUrl}/${record.receipt}`)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
    {
      title: "Expense Sheet",
      dataIndex: "calculationSheet",
      key: "calculationSheet",
      render: (_, record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(`${apiUrl}/${record.calculationSheet}`)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          {status === 0 ? "Pending" : status === 1 ? "Inprocess" : status === 2 ? "Approved" : "Rejected"}
        </SoftTypography>
      ),
    },       
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <EyeOutlined onClick={() => handleEyeIconClick(record)} style={{ cursor: "pointer" }} />
        </Space>
      ),
    },
  ];

  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEmployeesByManager();
  }, []);

  async function fetchEmployeesByManager() {
    try {
      setLoading(true);
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/Job/GetEmployee-JobDataby-Email/${loginEmail}`
        );
        const responseData = response.data;
        const managerName =
          responseData.personalInformation.firstName +
          " " +
          responseData.personalInformation.lastName;

        const employeesResponse = await axios.get(
          `${apiUrl}/api/ExpenseManagement/Get-Expense-by-Manager/${encodeURIComponent(
            managerName
          )}`
        );
        const employeesData = employeesResponse.data;

        if (Array.isArray(employeesData) && employeesData.length > 0) {
          setEmployees(employeesData);
        } else {
          console.error("No employee data found for the manager");
          setEmployees([]);
        }
      } else {
        console.error("User email not found in local storage");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }
  
  const handlePdfPreview = async (fileUrl) => {
    try {
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  // Function to handle eye icon click
  const handleEyeIconClick = (record) => {
    setSelectedEmployee(record); // Set the selected employee to display detail
  };

  const handleUpdateStatus = async (status) => {
    try {
      const requestData = {
        ExpenseManagementId: selectedEmployee.expenseManagementId,
        Status: status, // Pass the status dynamically
      };

      const response = await axios.post(
        `${apiUrl}/api/ExpenseManagement/Update-Expense-Status`,
        requestData
      );

      if (response.status === 200) {
        message.success("Expense status updated successfully");
        setSelectedEmployee(null); // Reset selectedEmployee after successful update
        fetchEmployeesByManager(); // Refresh employee list
      } else {
        message.error("Failed to update expense status");
      }
    } catch (error) {
      console.error("Error updating expense status:", error);
      message.error("Failed to update expense status");
    }
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>{selectedEmployee ? "Employee Detail" : "Employee Expense Request"}</h5>
            </div>
            <div className="card-body">
              {selectedEmployee ? ( // If selected employee exists, display detail
                <div>
                  <p className="h6-trainingdetail">
                    <strong>Expense ID:</strong> {selectedEmployee.expenseManagementId}
                  </p>
                  <p className="h6-trainingdetail">
                    <strong>Name:</strong> {selectedEmployee.employeeName}
                  </p>
                  <p className="h6-trainingdetail">
                    <strong>Email:</strong> {selectedEmployee.email}
                  </p>
                  <p className="h6-trainingdetail">
                    <strong>Request To:</strong> {selectedEmployee.manager}
                  </p>
                  <p className="h6-trainingdetail">
                    <strong>Request Date:</strong> {selectedEmployee.date}
                  </p>
                  <p className="h6-trainingdetail">
                    <strong>Description:</strong> {selectedEmployee.description}
                  </p>
                  <p className="h6-trainingdetail">
                    <strong>Status:</strong>{" "}
                    {selectedEmployee.status === 0
                      ? "Pending"
                      : selectedEmployee.status === 1
                      ? "Inprocess"
                      : selectedEmployee.status === 2
                      ? "Approved"
                      : "Rejected"}
                  </p>
                  <p className="h6-trainingdetail">
                    <strong>Receipt:</strong>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Space size="middle">
                      <FileOpenOutlined
                        onClick={() => handlePdfPreview(`${apiUrl}/${selectedEmployee.receipt}`)}
                        style={{ cursor: "pointer" }}
                      />
                    </Space>
                  </p>
                  <p className="h6-trainingdetail">
                    <strong>Expense Sheet:</strong> &nbsp;&nbsp;&nbsp;&nbsp;
                    <Space size="middle">
                      <FileOpenOutlined
                        onClick={() =>
                          handlePdfPreview(`${apiUrl}/${selectedEmployee.calculationSheet}`)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Space>
                  </p>
                  <Space size="middle">
                    <SoftButton
                      onClick={() => setSelectedEmployee(null)}
                      variant="outlined"
                      color="primary"
                    >
                      Close
                    </SoftButton>
                    {selectedEmployee.status === 0 && (
                      <>
                        <SoftButton
                          onClick={() => handleUpdateStatus("Rejected")}
                          variant="outlined"
                          color="error"
                        >
                          Reject
                        </SoftButton>
                        <SoftButton
                          onClick={() => handleUpdateStatus("Inprocess")}
                          variant="outlined"
                          color="success"
                        >
                          Inprocess
                        </SoftButton>
                      </>
                    )}
                  </Space>
                </div>
              ) : (
                <div className="row mb-3">
                  <Table
                    columns={columns}
                    dataSource={employees}
                    loading={loading}
                    rowKey={(record) => record.id}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeExpenseRequest;
