import { useState, useEffect, useContext } from "react";
import { Form, Radio, Input, Card, Row, Col, DatePicker, Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { CorrectiveActionContext } from "./CorrectiveActionContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "Components/authentication/components/Footer";
import moment from "moment";

function CorrectiveActionForm() {
  const { complaintId, email } = useParams();
  const navigate = useNavigate();
  const [complaintDetails, setComplaintDetails] = useState([]);
  const { setIsSubmitted } = useContext(CorrectiveActionContext);
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();

  const [formFields, setFormFields] = useState({
    departmentName: "",
    whenDate: "",
    improvement: "",
    associateComments: "",
    summarySession: "",
    leaderName: "",
    date: "",
  });

  const [warned, setWarned] = useState(""); // Local state for tracking "warned" radio button value

  useEffect(() => {
    fetchComplaintByEmail();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleWarnedChange = (value) => {
    setWarned(value); // Update the local state when "warned" radio button value changes
  };

  const handleSubmit = async () => {
    try {
      if (!complaintId) {
        throw new Error("Complaint ID is missing.");
      }
      await form.validateFields();
      if (complaintDetails.length > 0) {
        const com = complaintDetails[0];
        const data = {
          assosiateName: com.firstName,
          complaintId: complaintId,
          email: com.email,
          departmentName: com.departments,
          dateOfIncident: com.complaintDate.split("T")[0],
          typeOfWarning: form.getFieldValue("typeOfWarning"),
          detailOfIncident: com.detailofIncident,
          warnedPreviousl: warned,
          ifYes: warned === "Yes" ? form.getFieldValue("yes") : "",
          whenDate: warned === "Yes" ? formFields.whenDate : null,
          improvement: formFields.improvement,
          associateComments: formFields.associateComments,
          summarySession: formFields.summarySession,
          leaderName: formFields.leaderName,
          date: formFields.date,
        };

        setIsLoading(true);
        const response = await axios.post(
          `${apiUrl}/api/CorrectiveComplaintAction/Add-Corrective-Action?complaintId=${complaintId}`,
          data
        );
        setIsSubmitted(true);
        localStorage.setItem(`complaint_${complaintId}_submitted`, "true");
        navigate(`/complaintDetail/${com.complaintId}/${com.email}`);
      }
    } catch (error) {
      console.error("Validation or submission error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  async function fetchComplaintByEmail() {
    try {
      if (email) {
        const response = await axios.get(
          `${apiUrl}/api/CorrectiveComplaintAction/Get-Employee-Complaintl/${email}`
        );
        const responseData = response.data;
        setComplaintDetails(Array.isArray(responseData) ? responseData : [responseData]);
      } else {
        console.error("User email not found");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const { TextArea } = Input;

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <br />
        <Card title="Associate Corrective Action" className="card-professional">
          <Form form={form} layout="vertical">
            {complaintDetails.map((com) => (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Associate Name">
                      <Input
                        value={com.firstName}
                        readOnly
                        placeholder="Enter Associate Name here"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Associate Email">
                      <Input value={com.email} readOnly />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Dept Name *">
                      <Input
                        value={com.departments}
                        readOnly
                        placeholder="Enter department Name here"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Date of Incident">
                      <DatePicker
                        value={com.complaintDate ? moment(com.complaintDate.split("T")[0]) : null}
                        disabled
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <hr />

                <Form.Item
                  label="Type of Warning"
                  name="typeOfWarning"
                  rules={[{ required: true, message: "Type of Warning is required" }]}
                >
                  <Radio.Group>
                    <Radio value="Verbal Warning">Verbal Warning</Radio>
                    <Radio value="Written Warning">Written Warning</Radio>
                    <Radio value="Final Written Warning">Final Written Warning</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Has the associate been warned of a similar incident?"
                  name="warned"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Radio.Group onChange={(e) => handleWarnedChange(e.target.value)}>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>

                {warned === "Yes" && (
                  <>
                    <Form.Item
                      label="If yes, how and when?"
                      name="yes"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Radio.Group>
                        <Radio value="Verbal">Verbal</Radio>
                        <Radio value="Written">Written</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Col span={12}>
                      <Form.Item
                        label="When (dates):"
                        name="whenDate"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <DatePicker
                          value={formFields.whenDate ? moment(formFields.whenDate) : null}
                          onChange={(date, dateString) =>
                            handleInputChange({ target: { name: "whenDate", value: dateString } })
                          }
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Details of Incident">
                      <TextArea rows={3} value={com.detailofIncident} readOnly />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Action Plan(s) for Improvement"
                      name="improvement"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <TextArea
                        rows={3}
                        name="improvement"
                        value={formFields.improvement}
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Associate’s Comments"
                      name="associateComments"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <TextArea
                        rows={3}
                        name="associateComments"
                        value={formFields.associateComments}
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Summary of counseling session"
                      name="summarySession"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <TextArea
                        rows={3}
                        name="summarySession"
                        value={formFields.summarySession}
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <h6>
                  Further Violations May Result In Further Disciplinary Action, Up To and Including
                  Termination.
                </h6>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Leader’s Name"
                      name="leaderName"
                      rules={[{ required: true, message: "Leader's name is required" }]}
                    >
                      <Input
                        name="leaderName"
                        value={formFields.leaderName}
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Date"
                      name="date"
                      rules={[{ required: true, message: "Date is required" }]}
                    >
                      <DatePicker
                        value={formFields.date ? moment(formFields.date) : null}
                        onChange={(date, dateString) =>
                          handleInputChange({ target: { name: "date", value: dateString } })
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="end">
                  <Col>
                    <Button type="primary" loading={isLoading} onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </>
            ))}
          </Form>
        </Card>
        <Footer />
      </div>
    </DashboardLayout>
  );
}

export default CorrectiveActionForm;
