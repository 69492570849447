import { forwardRef } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import SoftBox from "StyleFeatures/SoftBox";
import SoftTypography from "StyleFeatures/SoftTypography";
import { menuItem, menuImage } from "examples/Items/NotificationItem/styles";

const NotificationItem = forwardRef(({ color, image, title, date, ...rest }, ref) => (
  <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
    <SoftBox mt={0.25} mr={2} ml={-2} mb={0.25}>
      {image}
    </SoftBox>
    <SoftBox>
      <SoftTypography variant="button" textTransform="capitalize" fontWeight="regular">
        <strong>{title}</strong>
      </SoftTypography>
      <SoftTypography
        variant="caption"
        color="secondary"
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 0.5,
        }}
      >
        {date}
      </SoftTypography>
    </SoftBox>
  </MenuItem>
));

NotificationItem.defaultProps = {
  color: "dark",
};

NotificationItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired, // Changed from array to string
  date: PropTypes.string.isRequired,
};

export default NotificationItem;

// import { forwardRef } from "react";
// import PropTypes from "prop-types";

// // @mui material components
// import MenuItem from "@mui/material/MenuItem";
// import Icon from "@mui/material/Icon";

// import SoftBox from "StyleFeatures/SoftBox";
// import SoftTypography from "StyleFeatures/SoftTypography";

// // custom styles for the NotificationItem
// import { menuItem, menuImage } from "examples/Items/NotificationItem/styles";
// import { Avatar, Button } from "@mui/material";

// const NotificationItem = forwardRef(({ color, image, title, date, ...rest }, ref) => (
//   <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
//     <SoftBox
//     //   width="46px"
//     //   height="46px"
//       mt={0.25}
//       mr={2}
//       ml={-2}
//       mb={0.25}
//     //   borderRadius="50px"
//     //   sx={(theme) => menuImage(theme, { color })}
//     >
//       {image}
//     </SoftBox>
//     <SoftBox>
//       <SoftTypography variant="button" textTransform="capitalize" fontWeight="regular">
//         <strong>{title[0]}</strong> {title[1]}
//       </SoftTypography>
//       <SoftTypography
//         variant="caption"
//         color="secondary"
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           mt: 0.5,
//         }}
//       >
//         <SoftTypography variant="button" color="secondary">
//           {/* <Icon
//             sx={{
//               lineHeight: 1.2,
//               mr: 0.5,
//             }}
//           >
//             watch_later
//           </Icon> */}
//         </SoftTypography>
//         {date}
//       </SoftTypography>
//     </SoftBox>
//   </MenuItem>
// ));

// // Setting default values for the props of NotificationItem
// NotificationItem.defaultProps = {
//   color: "dark",
// };

// // Typechecking props for the NotificationItem
// NotificationItem.propTypes = {
//   color: PropTypes.oneOf([
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "light",
//     "dark",
//   ]),
//   image: PropTypes.node.isRequired,
//   title: PropTypes.arrayOf(PropTypes.string).isRequired,
//   date: PropTypes.string.isRequired,
// };

// export default NotificationItem;
