import React, { useState, useEffect } from "react";
import { Table, Select, Button, Spin, message, Checkbox, Card } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import {
  authenticateUser,
  fetchEmployees,
  markAsPaid,
  downloadReport,
  downloadMultipleReports,
} from "./FinanceService";
import { generateSingleReport, generateMultipleReport } from "./PDFGenrator";
import SoftBox from "StyleFeatures/SoftBox";
import SoftButton from "StyleFeatures/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "Components/authentication/components/Footer";

const { Option } = Select;

const EmployeeFinance = () => {
  const [token, setToken] = useState("");
  const [employees, setEmployees] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1); // Current month (1-12)
  const [year, setYear] = useState(new Date().getFullYear()); // Current year
  const [loading, setLoading] = useState(false); // Initial loading state
  const [getloading, setgetLoading] = useState(false); // Initial loading state

  useEffect(() => {
    const authenticateAndFetchData = async () => {
      try {
        setLoading(true);
        const jwtToken = await authenticateUser();
        setToken(jwtToken);

        // Fetch employee data for the initial month and year
        const employeesList = await fetchEmployees(month, year, jwtToken);
        setEmployees(employeesList);
      } catch (error) {
        console.error("Error during authentication or data fetch:", error);
      } finally {
        setLoading(false);
      }
    };

    authenticateAndFetchData();
  }, []); // Dependency array is empty to run only once on mount

  useEffect(() => {
    // Fetch employees whenever month or year changes
    const fetchEmployeeData = async () => {
      if (token) {
        try {
          // setgetLoading(true);
          const employeesList = await fetchEmployees(month, year, token);
          setEmployees(employeesList);
        } catch (error) {
          console.error("Error fetching employees:", error);
          // Check if error response is available
          if (error.response && error.response.data) {
            message.error(`Failed to fetch employees: ${error.response.data}`);
          } else {
            message.error("Failed to fetch employees.");
          }
        } finally {
          // setgetLoading(false);
        }
      }
    };
    fetchEmployeeData();
  }, [token, month, year]);

  const handleDownload = async (employeeId) => {
    try {
      const reportData = await downloadReport(employeeId, month, year, token);
      generateSingleReport(reportData, month, year);
    } catch (error) {
      console.error("Error downloading report:", error);
      // Check if error response is available
      if (error.response && error.response.data) {
        message.error(`Failed to download report: ${error.response.data}`);
      } else {
        message.error("Failed to download report.");
      }
    }
  };

  const handleDownloadAll = async () => {
    try {
      const reportData = await downloadMultipleReports(month, year, token);
      if (!reportData || reportData.length === 0) {
        console.error("No report data available");
        message.error("No report data available.");
        return;
      } else {
        generateMultipleReport(reportData, month, year);
      }
    } catch (error) {
      console.error("Error downloading all reports:", error);
      // Check if error response is available
      if (error.response && error.response.data) {
        message.error(`Failed to download all reports: ${error.response.data}`);
      } else {
        message.error("Failed to download all reports.");
      }
    }
  };
  const handleMarkAsPaid = async (employeeId) => {
    try {
      await markAsPaid(employeeId, month, year, token);
      message.success("Marked as paid successfully.");
      // Refresh employee data
      const updatedEmployees = await fetchEmployees(month, year, token);
      setEmployees(updatedEmployees);
    } catch (error) {
      console.error("Error marking as paid:", error);

      // Check if error response is available
      if (error.response && error.response.data) {
        message.error(`${error.response.data}`);
      } else {
        message.error("Failed to mark as paid.");
      }
    }
  };

  const columns = [
    { title: "Employee ID", dataIndex: "employeeId", key: "employeeId" },
    { title: "Name", dataIndex: "name", key: "name", render: (text) => text || "N/A" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Role", dataIndex: "role", key: "role" },
    {
      title: "Paid",
      dataIndex: "isPaid",
      key: "isPaid",
      render: (text) => (text ? "Paid" : "Un-Paid"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Checkbox
          checked={record.isPaid}
          disabled={record.isPaid} // Disable checkbox if already paid
          onChange={(e) => handleMarkAsPaid(record.employeeId, e.target.checked)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => handleDownload(record.employeeId)}>
          <DownloadOutlined />
        </Button>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <Spin
        spinning={loading}
        size="large"
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <Card title="Finance Report" className="card-professional">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Select
                  value={month}
                  onChange={(value) => setMonth(value)}
                  style={{ width: 120, marginRight: 20 }}
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <Option key={i + 1} value={i + 1}>{`Month ${i + 1}`}</Option>
                  ))}
                </Select>
                <Select
                  value={year}
                  onChange={(value) => setYear(value)}
                  style={{ width: 120, marginRight: 20 }}
                >
                  {Array.from({ length: 10 }, (_, i) => (
                    <Option key={2020 + i} value={2020 + i}>{`Year ${2020 + i}`}</Option>
                  ))}
                </Select>
                <SoftBox color="text" style={{ marginRight: "20px" }}>
                  <div style={{ display: "inline-block" }}>
                    <Button type="primary" onClick={handleDownloadAll}>
                      <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Finance
                      Reports
                    </Button>
                  </div>
                </SoftBox>
              </div>
              <br></br>
              <div className="row mb-3">
                <Table
                  className="table-responsive"
                  columns={columns}
                  dataSource={employees}
                  rowKey="employeeId"
                />
              </div>
            </Card>
          </div>

          <Footer />
        </div>
      </Spin>
    </DashboardLayout>
  );
};

export default EmployeeFinance;
