import React from "react";
import DashboardLayout from "../empNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../empNavbar/Navbars/DashboardNavbar";
import { Table, Button, Tooltip, Card } from "antd";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Service from "./Service";

function ShortListEmployeeForm() {
  const {
    employeeContract,
    employeeDetails,
    columns,
    selectedTime,
    employeeData,
    isAllFilesSelected,
    handleSelectedTime,
    handleTimeSelect,
    formatDateTime,
    handleShortlistClick,
    contractAcceptance,
    selectedPdf,
    showNavbar,
    submittedEmployees,
    isContractSubmitted,
    isTimeSubmitted,
    employeeInterview,
    setSelectedTime,
    setShowNavbar,
  } = Service();

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        {showNavbar && <DashboardNavbar />}
        <div className="body flex-grow-1 px-3">
          <ToastContainer />
          <Card title="Interview Invitation" className="card-professional">
            {employeeInterview &&
              employeeInterview.firstDateTime &&
              !employeeContract.ndafile &&
              !employeeContract.ncafile &&
              !employeeContract.employeeAgreement &&
              !employeeContract.i_nine &&
              !employeeContract.w_four && (
                <div key={employeeInterview.interviewId} className="row">
                  {employeeInterview.status === 3 && !isTimeSubmitted ? (
                    <>
                      <p style={{ fontSize: "medium" }}>
                        Dear{" "}
                        <strong style={{ color: "rgb(255, 52, 6)" }}>
                          {employeeInterview.firstName + " " + employeeInterview.lastName}
                        </strong>
                        , please select a time for your interview.
                      </p>
                      <div>
                        <input
                          type="radio"
                          id="firstDateTime"
                          name="interviewTime"
                          value={employeeInterview.firstDateTime}
                          checked={selectedTime === employeeInterview.firstDateTime}
                          onChange={(event) => {
                            setSelectedTime(event.target.value);
                            handleTimeSelect(employeeInterview.employeeId, event);
                          }}
                          style={{ marginRight: "10px" }}
                        />
                        <label htmlFor="firstDateTime" style={{ fontSize: "medium" }}>
                          First Time :: ({formatDateTime(employeeInterview.firstDateTime)})
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="secondDateTime"
                          name="interviewTime"
                          value={employeeInterview.secondDateTime}
                          onChange={(event) =>
                            handleTimeSelect(employeeInterview.employeeId, event)
                          }
                          style={{ marginRight: "10px" }}
                        />
                        <label htmlFor="secondDateTime" style={{ fontSize: "medium" }}>
                          Second Time :: ({formatDateTime(employeeInterview.secondDateTime)})
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="thirdDateTime"
                          name="interviewTime"
                          value={employeeInterview.thirdDateTime}
                          onChange={(event) =>
                            handleTimeSelect(employeeInterview.employeeId, event)
                          }
                          style={{ marginRight: "10px" }}
                        />
                        <label htmlFor="thirdDateTime" style={{ fontSize: "medium" }}>
                          Third Time :: ({formatDateTime(employeeInterview.thirdDateTime)})
                        </label>
                      </div>
                      <br></br>
                      <br></br>
                      <div className="col-md-10">
                        <Button
                          type="primary"
                          onClick={handleSelectedTime}
                          disabled={isTimeSubmitted}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className="col-md-10">
                      <p style={{ fontSize: "medium", marginTop: "10px", color: "orange" }}>
                        Your interview schedule has been sent to the Datagonomix team. Please wait
                        for the next step.
                      </p>
                    </div>
                  )}
                </div>
              )}

            {!employeeContract.ndafile &&
            !employeeContract.ncafile &&
            !employeeContract.employeeAgreement &&
            !employeeContract.i_nine &&
            !employeeContract.w_four &&
            !employeeContract.offerLetter &&
            (!employeeInterview || !employeeInterview.firstDateTime) ? (
              <div>
                {employeeDetails.map((employee) => (
                  <div key={employee.key} className="row">
                    {employee.status === 1 ? (
                      <>
                        {!submittedEmployees[employee.id] ? (
                          <>
                            <div className="col-md-10">
                              <div className="form-group">
                                <p style={{ fontSize: "medium" }}>
                                  Dear{" "}
                                  <strong>
                                    {employee.firstName} {employee.lastName}
                                  </strong>
                                  ,<br />
                                  <br />I hope this message finds you well.<br></br>
                                  <br></br>I am writing to inform you that you have been selected
                                  for an interview for the <strong>{employee.forJobApply}</strong>{" "}
                                  role at Datagonomix on{" "}
                                  <strong>{formatDateTime(employee.dateAvailable)}</strong>. Your
                                  qualifications and experience have impressed us, and we believe
                                  you would make a valuable addition to our team. To proceed with
                                  the interview process, we kindly request your acceptance of this
                                  invitation. Once confirmed, we will provide you with further
                                  details regarding the next steps and scheduling.
                                </p>
                              </div>
                            </div>
                            <div className="col-md-10">
                              <Button
                                type="primary"
                                onClick={() => handleShortlistClick(employee)}
                                disabled={submittedEmployees[employee.id]}
                              >
                                Submit
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-10">
                            <p style={{ fontSize: "medium", color: "orange", marginTop: "10px" }}>
                              Your request has been sent to the Datagonomix team. Please wait for
                              the next interview step.
                            </p>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="col-md-10">
                        <p style={{ fontSize: "medium", color: "orange" }}>
                          Your request has been sent to the Datagonomix team. Please wait for the
                          next interview step.
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : null}

            {employeeContract && (
              <div className="row">
                {employeeContract.employeeJobApplication?.status === 6 ? (
                  <>
                    {!isContractSubmitted ? (
                      <>
                        <div className="row mb-3">
                          <Table
                            className="table-responsive"
                            dataSource={[
                              employeeContract.ndafile && {
                                key: "ndafile",
                                name: "NDA File",
                                file: employeeContract.ndafile,
                              },
                              employeeContract.ncafile && {
                                key: "ncafile",
                                name: "NCA File",
                                file: employeeContract.ncafile,
                              },
                              employeeContract.employeeAgreement && {
                                key: "employeeAgreement",
                                name: "Employee Agreement",
                                file: employeeContract.employeeAgreement,
                              },
                              employeeContract.i_nine && {
                                key: "i_nine",
                                name: "I-9 File",
                                file: employeeContract.i_nine,
                              },
                              employeeContract.w_four && {
                                key: "w_four",
                                name: "W-9 File",
                                file: employeeContract.w_four,
                              },
                              employeeContract.offerLetter && {
                                key: "offerLetter",
                                name: "Offer Letter",
                                file: employeeContract.offerLetter,
                              },
                            ].filter(Boolean)} // Remove null entries
                            pagination={false}
                            columns={columns}
                          />
                        </div>

                        <div className="col-md-12">
                          <Tooltip
                            title={!isAllFilesSelected ? "Please select files" : ""}
                            disableHoverListener={isAllFilesSelected || isContractSubmitted} // Prevent tooltip when not needed
                            placement="top" // Adjust placement as needed
                          >
                            <span>
                              <Button
                                variant="outlined"
                                type="primary"
                                disabled={!isAllFilesSelected || isContractSubmitted}
                                onClick={() =>
                                  contractAcceptance(
                                    employeeDetails[0].email,
                                    employeeDetails[0].employeeId,
                                    employeeContract.status,
                                    employeeContract.ndafile,
                                    employeeContract.ncafile,
                                    employeeContract.employeeAgreement,
                                    employeeContract.i_nine,
                                    employeeContract.w_four,
                                    employeeContract.offerLetter
                                  )
                                }
                              >
                                Submit
                              </Button>
                            </span>
                          </Tooltip>
                        </div>
                      </>
                    ) : (
                      <div className="col-md-12">
                        <p style={{ fontSize: "medium", color: "orange" }}>
                          Your files have been sent to the Datagonomix team. The team will provide
                          you an email and new credentials for the employee dashboard.
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  ![1, 2, 3, 4, 5].includes(employeeData?.status) && ( // Hide message if status is 1, 2, 3, 4, or 5
                    <div className="col-md-12">
                      <p style={{ fontSize: "medium", color: "orange" }}>
                        Your files have been sent to the Datagonomix team. The team will provide you
                        an email and new credentials for the employee dashboard.
                      </p>
                    </div>
                  )
                )}
              </div>
            )}
          </Card>
        </div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <embed src={selectedPdf} type="application/pdf" width="100%" height="450px" />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setShowNavbar(true)}
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ShortListEmployeeForm;
