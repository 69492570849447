import React, { useState } from "react";
import { Button, Card, Col, Form, Input, Row, Spin, Typography, message } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "Components/authentication/components/Footer";
import Service from "./Service";

const { Title } = Typography;

function ChildManagerFields() {
  const { isLoading, handleCancel, handleChange, handleSubmit, errors, formData } = Service();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        title="Add Associate Managers"
        className="card-professional"
        style={{ margin: "24px" }}
      >
        <Form layout="vertical" onSubmitCapture={handleSubmit}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Manager Name *"
                validateStatus={errors.name ? "error" : ""}
                help={errors.name}
              >
                <Input
                  name="name"
                  placeholder="Enter manager name here"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Email *"
                validateStatus={errors.email ? "error" : ""}
                help={errors.email}
              >
                <Input
                  name="email"
                  placeholder="Enter manager email here"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Password *"
                validateStatus={errors.password ? "error" : ""}
                help={errors.password}
              >
                <Input.Password
                  name="password"
                  placeholder="Enter strong password here"
                  value={formData.password}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}
                style={{ marginRight: "10px" }}
              >
                {isLoading ? "Please Wait..." : "Submit"}
              </Button>
              <Button type="default" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default ChildManagerFields;
