import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "StyleFeatures/SoftButton";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "Components/authentication/components/Footer";

function Terminate() {
  const navigate = useNavigate();
  const { personalId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [complaintDescription, setComplaintDescription] = useState("");
  const [terminationFile, setTerminationFile] = useState(null);
  const [terminated, setTerminated] = useState(false);
  const [inputsDisabled, setInputsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    navigate("/people");
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (!complaintDescription || !terminationFile) {
        toast.error("Fields Required", {
          autoClose: 5000,
          style: { background: "lightcoral", color: "white" },
        });
        setLoading(false);
        return false;
      }

      const confirmed = window.confirm("Are you sure to terminate this employee?");
      if (!confirmed) {
        setLoading(false);
        return false;
      }

      const formData = new FormData();
      formData.append("complaintDescription", complaintDescription);
      formData.append("file", terminationFile);

      const response = await fetch(`${apiUrl}/api/PersonalInformation/Terminate/${personalId}`, {
        method: "POST",
        body: formData,
      });

      const responseText = await response.text();

      try {
        const data = JSON.parse(responseText);

        if (response.ok) {
          setTerminated(true);
          setInputsDisabled(true);
          toast.success(data.message || "Employee terminated successfully", {
            autoClose: 3000,
            style: { background: "lightgreen", color: "white" },
          });
          return true;
        } else {
          toast.error("Failed to terminate employee", {
            autoClose: 5000,
            style: { background: "lightcoral", color: "white" },
          });
        }
      } catch (error) {
        toast.error(responseText, {
          autoClose: 5000,
          style: { background: "lightcoral", color: "white" },
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Error occurred while processing", {
        autoClose: 5000,
        style: { background: "lightcoral", color: "white" },
      });
    }

    setLoading(false);
    return false;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const success = await handleSubmit();
    if (success) {
      // `setTerminated(true)` will handle showing the button
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="card">
        <div className="card-header" style={{ position: "relative" }}>
          <h5>Terminate</h5>
        </div>
        <div className="card-body">
          <form onSubmit={onSubmit} encType="multipart/form-data">
            <ToastContainer />
            <input type="hidden" name="personalId" value={personalId} />
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="mb-3">
                  <label
                    htmlFor="complaintDescription"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Complaint Description *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="complaintDescription"
                    placeholder="Enter Complaint Description here"
                    value={complaintDescription}
                    onChange={(e) => setComplaintDescription(e.target.value)}
                    disabled={inputsDisabled}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="mb-3">
                  <label
                    htmlFor="terminationFile"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Attach Termination *
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="terminationFile"
                    onChange={(e) => setTerminationFile(e.target.files[0])}
                    disabled={inputsDisabled}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              {terminated ? (
                <SoftButton
                  variant="outlined"
                  style={{ float: "inline-end" }}
                  color="info"
                  size="small"
                  onClick={() => navigate("/employees")}
                >
                  Go to Employee List
                </SoftButton>
              ) : (
                <>
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end" }}
                    color="info"
                    size="small"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "Submit"}
                  </SoftButton>
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end", marginRight: "10px" }}
                    color="error"
                    size="small"
                    onClick={handleCancel}
                  >
                    Cancel
                  </SoftButton>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </DashboardLayout>
  );
}

export default Terminate;
