import React from "react";
import { Card, Row, Col } from "antd";
import { CalendarFilled } from "@ant-design/icons";
import "./holidays.scss";

const holidays = [
  { date: "Monday, January 01", name: "New Year’s Day" },
  { date: "Monday, January 20", name: "Inauguration Day" },
  { date: "Monday, May 27", name: "Memorial Day" },
  { date: "Wednesday, June 19", name: "Juneteenth National Independence Day" },
  { date: "Thursday, July 04", name: "Independence Day" },
  { date: "Monday, September 02", name: "Labor Day" },
  { date: "Monday, October 14", name: "Columbus Day" },
  { date: "Monday, November 11", name: "Veterans Day" },
  { date: "Thursday, November 28", name: "Thanksgiving Day" },
  { date: "Wednesday, December 25", name: "Christmas Day" },
];

function UpComingHolidays() {
  return (
    <Card title="Upcoming Holidays" className="card-professional">
      <div className="holiday-list">
        {holidays.map((holiday, index) => (
          <Row key={index} gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={24}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CalendarFilled
                  style={{ fontSize: "36px", color: "#226c87", marginRight: "8px" }}
                />
                <div>
                  <p style={{ margin: 0 }}>{holiday.name}</p>
                  <p style={{ margin: 0, color: "#888" }}>{holiday.date}</p>
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </Card>
  );
}

export default UpComingHolidays;
